import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'clsx';
import { ListItemText } from '@mui/material';
import { cloneDeep, debounce } from 'lodash';

import { formatLocalDateTime } from '@/utils';
import { RequestTypes } from '@/utils/types';
import { FilterType } from '@/utils/constants';
import {
  Button,
  Checkbox,
  DateTimePicker,
  MultiRadioDropdown,
  MultiSelect,
  SearchField,
  Switch,
  Typography,
} from '@/components';

import styles from './FilterPanel.module.css';

const PAGE_NAME = 'TaskLog_FilterPanel';

export const FilterPanel = observer(({ localFilters, setLocalFilters, onApply, onClose }) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isDateChanged, setIsDateChanged] = useState(false);
  const customClasses = { root: styles.verticalItem, paper: styles.verticalPopover };
  const anchorOrigin = {
      vertical: 'top',
      horizontal: 'left',
    },
    transformOrigin = {
      vertical: 'top',
      horizontal: 'right',
    };

  const handleSelectedValueChange = (filterKey, value) => {
    const newFilters = cloneDeep(localFilters);
    newFilters[filterKey].selectedValues = value;

    setLocalFilters(newFilters);
  };

  const handleOpenPopover = (open) => {
    if (!open) {
      onApply();
    }
    setIsFilterOpen(open);
  };

  useEffect(() => {
    if (isDateChanged) {
      onApply();
      setIsDateChanged(false);
    }
  }, [isDateChanged]);

  const debouncedErrorChangeHandler = useCallback(
    debounce((e) => handleSelectedValueChange(FilterType.error, [e.target.value]), 500),
    [],
  );

  return (
    <div
      className={cx(styles.filtersGroup, styles.verticalFiltersGroup)}
      id={`${PAGE_NAME}_container`}
    >
      {Object.keys(localFilters).map((filterKey) => {
        const {
          label,
          selectedValues: selectedValue,
          values,
          hasSearchBar,
          hasClear,
        } = localFilters[filterKey];
        const options = values.map((item) => ({
          value: item.value,
          label: item.label,
          type: item.type,
        }));

        if (filterKey === FilterType.type) {
          return (
            <div key={filterKey} className={styles.filterItem} id={`${PAGE_NAME}_dropdown`}>
              <MultiRadioDropdown
                id={`service-filter-${filterKey}`}
                key={filterKey}
                value={selectedValue}
                handleOpenPopover={handleOpenPopover}
                defaultValue={RequestTypes.all}
                placeholder={label}
                options={options}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                classes={customClasses}
                onChange={(value) => handleSelectedValueChange(filterKey, value)}
              />
            </div>
          );
        }

        if (filterKey === FilterType.updatedAfter || filterKey === FilterType.createdAfter) {
          return (
            <div key={filterKey} className={styles.filterItem} id={`${PAGE_NAME}_dateTime`}>
              <DateTimePicker
                isDate
                isSmallSize
                placeholder={label}
                onApply={onApply}
                className={styles.dateFilter}
                value={selectedValue?.[0]}
                maxDate={Date.now()}
                handleOpenPopover={handleOpenPopover}
                onChange={(value) => {
                  handleSelectedValueChange(filterKey, [formatLocalDateTime(value, 'MM/DD/YYYY')]);
                  setIsDateChanged(true);
                }}
              />
            </div>
          );
        }

        if (filterKey === FilterType.error) {
          return (
            <SearchField
              id="build-error-search"
              placeholder="Search by build error"
              value={selectedValue?.[0]}
              classes={{ root: styles.applyButton }}
              onChange={debouncedErrorChangeHandler}
            />
          );
        }

        return (
          <div key={filterKey} className={styles.filterItem} id={`${PAGE_NAME}_dropdown`}>
            <MultiSelect
              id={`btn-filter-${filterKey}`}
              size="small"
              key={filterKey}
              value={selectedValue}
              placeholder={label}
              options={options}
              isSearchBar={hasSearchBar}
              hasClear={hasClear}
              classes={customClasses}
              anchorOrigin={anchorOrigin}
              transformOrigin={transformOrigin}
              renderValue={(selected, placeholder) => {
                if (selected.length === 0 && placeholder) {
                  return <Typography variant="body2">{placeholder}</Typography>;
                }
                const selectedLabel =
                  options.find(({ value }) => value === selected?.[0])?.label || '';
                const label = `${selectedLabel.slice(0, selected.length === 1 ? 28 : 20)}${
                  selectedLabel.length > 20 ? '...' : ''
                }`;
                if (selected.length > 1) {
                  return `${label} +${selected.length - 1} more`;
                }
                return label;
              }}
              handleOpenPopover={handleOpenPopover}
              onChange={(value) => handleSelectedValueChange(filterKey, value)}
              MenuItemComponent={({ label, checked, match }) => {
                if (filterKey === FilterType.viewBy) {
                  return (
                    <>
                      <Switch checked={checked} id={`${PAGE_NAME}_switchItem`} />
                      <ListItemText>{label}</ListItemText>
                    </>
                  );
                }
                return (
                  <>
                    <Checkbox
                      checked={checked}
                      id={`${PAGE_NAME}_checkboxItem`}
                      classes={{ formRoot: styles.menuItemCheckbox }}
                    />
                    <span className={styles.truncatedText}>
                      {match ? (
                        <>
                          {label.slice(0, match[0])}
                          <span className={styles.highlight}>
                            {label.slice(match[0], match[1])}
                          </span>
                          {label.slice(match[1])}
                        </>
                      ) : (
                        label
                      )}
                    </span>
                  </>
                );
              }}
            />
          </div>
        );
      })}
      <Button
        id="btn-filter"
        size="small"
        variant="secondary"
        onClick={() => {
          onApply();
          onClose();
        }}
        className={styles.applyButton}
      >
        {isFilterOpen ? 'Apply' : 'OK'}
      </Button>
    </div>
  );
});
