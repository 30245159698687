import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { TaskBullet } from '@/components';
import { useMainStore } from '../../stores/useMainStore';

import styles from './TicketTree.module.css';

const PAGE_NAME = 'TaskDetailList';

export const TicketTree = observer(
  ({ data, ticketId, scrollTicketId, isDragMode, onActiveTicket, onMoveTicket }) => {
    const containerRef = useRef();
    const { detailStore } = useMainStore();

    useEffect(() => {
      if (scrollTicketId && data.length > 0) {
        const pos = data.findIndex((item) => item.ticketId === scrollTicketId);
        let top = 0;
        for (let i = 0; i < pos; i++) {
          top += containerRef.current.children[i]?.offsetHeight || 0;
        }
        containerRef.current.scrollTo({
          top,
          behavior: 'smooth',
        });
      }
    }, [scrollTicketId, data, containerRef]);

    return (
      <div id={`${PAGE_NAME}_container`} className={styles.detailListContainer} ref={containerRef}>
        {data.map((ticket) => (
          <TaskBullet
            key={ticket.ticketId}
            id={`${PAGE_NAME}_item`}
            organizationId={detailStore.organizationId}
            variant={ticket.workflow}
            automation={ticket.automation}
            selected={ticketId === ticket.ticketId}
            title={ticket.title}
            assignName={ticket.assignedToName}
            assignEmail={ticket.assignedToEmail}
            assignType={ticket.assignedToType}
            status={ticket.state}
            externalStatus={ticket.externalStatus}
            generation={ticket.ticketPath.length - 1}
            ticketId={ticket.ticketId}
            ticketPath={ticket.ticketPath}
            isDragMode={isDragMode}
            onMoveTicket={onMoveTicket}
            onClick={() => onActiveTicket(ticket.ticketId)}
          />
        ))}
      </div>
    );
  },
);
