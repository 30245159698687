import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Close } from '@mui/icons-material';
import clsx from 'clsx';

import { theme } from '@/config';
import {
  Button,
  Typography,
  MultiLineInput,
  Dropdown,
  TypeaheadDropdown,
  TextField,
  LoadingIndicator,
  ConfirmDialog,
  DateTimePicker,
  CompactAttachment,
  Tooltip,
  AsyncComponent,
} from '@/components';
import { userStore, withStore } from '@/store';
import { useMainStore } from '../../stores/useMainStore';
import { NotificationText, PriorityOptions } from '@/utils/types';
import { getAssignFromId, getFileSizeError, isValidQuantity, showErrorNotification } from '@/utils';
import { MAX_QUANTITY, SECOND, WARNING_COLOR } from '@/utils/constants';

import styles from './CreateForm.module.css';
import SpecificForms from '../DetailWorkflowContent/SpecificForms';

const PAGE_NAME = 'CreateForm';

const CreateForm = ({ goBack, onClose, handleClose }) => {
  const { createTaskStore, workflowStore, taskLogStore } = useMainStore();
  const [isCreating, setIsCreating] = useState(false);
  const [quantityErrorOpen, setQuantityErrorOpen] = useState(false);
  const { selectedWorkflowType } = createTaskStore;
  const {
    title,
    description,
    priority,
    assignedTo,
    attachedFiles,
    quantity,
    ownedBy,
    estimatedTime,
  } = createTaskStore.createTicket;
  const isQuestionPage = createTaskStore.isQuestion;
  const isValidRequest =
    title &&
    assignedTo &&
    !getFileSizeError(attachedFiles) &&
    (isQuestionPage || (ownedBy && estimatedTime && isValidQuantity(quantity)));
  const isValidWorkflow =
    (!workflowStore.isSingleServiceType && workflowStore.isValidTemplate) ||
    (workflowStore.isSingleServiceType && workflowStore.staticFormValue.isValid);
  const isSubmitDisabled =
    !isValidRequest || (workflowStore.workflow === 'WeeksOfCover' && !isValidWorkflow);

  useEffect(() => {
    if (Number(quantity) >= MAX_QUANTITY) {
      setQuantityErrorOpen(true);
    } else {
      setQuantityErrorOpen(false);
    }
  }, [quantity]);

  const handleSubmitTicket = async () => {
    if (!userStore.currentUser.isContributor) {
      showErrorNotification(NotificationText.createTicketPermissionWarning, {
        autoHideDuration: SECOND,
      });
      return;
    }
    if (!isSubmitDisabled) {
      setIsCreating(true);
      try {
        const ticketInfo = await workflowStore.submitWorkflow(selectedWorkflowType);
        if (ticketInfo.ticketId) {
          workflowStore.reset();
          createTaskStore.reset();
          handleClose(ticketInfo.ticketId);
          taskLogStore.refreshTable();
        }
      } catch (err) {
        showErrorNotification(err.message);
      }
      setIsCreating(false);
    }
  };

  const handleCancel = () => {
    if (isQuestionPage) {
      onClose();
    } else {
      goBack(true);
    }
  };

  const renderAssign = (value, userOptions, helperText = 'Select Assignee') => {
    const assignItem = getAssignFromId(value, userOptions);
    if (!assignItem || !assignItem.label)
      return (
        <Typography
          variant="body1"
          id={`${PAGE_NAME}_assignValue`}
          className={styles.placeholder}
          style={{
            color: theme.palette.neutrals.greyMed,
          }}
        >
          {helperText}
        </Typography>
      );
    return <Typography variant="body1">{assignItem.label || 'None'}</Typography>;
  };

  if (isCreating) {
    return (
      <div id={`${PAGE_NAME}_loading`} className={styles.loadingContainer}>
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className={styles.createTaskContainer}>
      <div className={styles.createTaskTitle}>
        <Button
          variant="tertiary"
          startIcon={<ArrowBackIcon />}
          onClick={() => goBack(false)}
          id={`${PAGE_NAME}_backButton`}
        >
          Back
        </Button>
        <div className={styles.createTaskTitleText}>
          <Typography variant="h4">{createTaskStore.organizationInfo?.name}</Typography>
        </div>
        <div className={styles.createTaskCloseBtn}>
          <Tooltip title="Close" placement="top" arrow>
            <IconButton
              onClick={onClose}
              classes={{ root: styles.closeButton }}
              id="Modal_CloseIconButton"
            >
              <Close color="info" />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <div className={styles.contentContainer}>
        <Typography variant="h5">{createTaskStore.workflowInfo?.title}</Typography>

        <Typography variant="body1">{createTaskStore.workflowInfo?.description}</Typography>

        <div className={styles.row}>
          <div className={clsx(styles.contentGroup, styles.fullWidth)}>
            <div className={styles.labelWrapper}>
              <Typography variant="body2">{isQuestionPage ? 'Question*' : 'Title*'}</Typography>
              <MultiLineInput
                size="small"
                placeholder={`Describe your ${isQuestionPage ? 'Question' : 'Title'}`}
                error={!title}
                value={title}
                onChange={(value) => createTaskStore.updateData({ title: value })}
                id={`${PAGE_NAME}_taskTitle`}
              />
            </div>
            <div className={styles.labelWrapper}>
              <Typography variant="body2">Description</Typography>
              <MultiLineInput
                size="small"
                placeholder="Add any additional information you would like to share with us."
                value={description}
                onChange={(value) => createTaskStore.updateData({ description: value })}
                id={`${PAGE_NAME}_description`}
              />
            </div>
            <SpecificForms
              workflowInfo={createTaskStore.workflowInfo}
              showWarning={isValidRequest}
            />
          </div>
          <div className={styles.contentGroup}>
            {!isQuestionPage && (
              <Dropdown
                size="small"
                label="Priority*"
                id={`${PAGE_NAME}_priority`}
                placeholder="Select Priority"
                value={priority}
                options={PriorityOptions}
                onChange={(e) => createTaskStore.updateData({ description: e.target.value })}
              />
            )}
            <TypeaheadDropdown
              size="small"
              label={`${isQuestionPage ? 'Question' : 'Service'} Owner*`}
              tooltipText={`The owner monitors the progress of the ${
                isQuestionPage ? 'question' : 'task'
              }.`}
              id={`${PAGE_NAME}_taskOwner_dropdown`}
              value={ownedBy}
              options={createTaskStore.assignOptions}
              error={!ownedBy}
              classes={{
                root: styles.noFlex,
                container: styles.assignContainer,
                svgIcon: styles.moreIcon,
              }}
              renderValue={(value) =>
                renderAssign(value, createTaskStore.assignOptions, 'Select Owner')
              }
              onChange={(value) => createTaskStore.updateData({ ownedBy: value })}
            />
            <TypeaheadDropdown
              label="Assign to*"
              id={`${PAGE_NAME}_assignTo_dropdown`}
              error={!assignedTo}
              value={assignedTo}
              options={createTaskStore.assignOptions}
              classes={{
                root: styles.noFlex,
                container: styles.assignContainer,
                svgIcon: styles.moreIcon,
              }}
              renderValue={(value) => renderAssign(value, createTaskStore.assignOptions)}
              onChange={(value) => createTaskStore.updateData({ assignedTo: value })}
            />
            {!isQuestionPage && (
              <>
                <TextField
                  type="number"
                  label="Quantity*"
                  placeholder="Input Quantity"
                  value={quantity}
                  error={!isValidQuantity(quantity)}
                  onChange={(e) => createTaskStore.setQuantity(e.target.value)}
                  id={`${PAGE_NAME}_quantity_textField`}
                />
                <DateTimePicker
                  isDate
                  id={`${PAGE_NAME}_ECD_datePicker`}
                  label={'Estimated Completion Date*'}
                  helperText="Select Date"
                  value={estimatedTime}
                  disabled
                  error={!estimatedTime}
                  onChange={(value) => createTaskStore.updateData({ estimatedTime: value })}
                  className={styles.ecdWrapper}
                  style={{ flex: '0 1' }}
                />
              </>
            )}
            <CompactAttachment
              contentClassName={styles.uploader}
              files={attachedFiles}
              error={getFileSizeError(attachedFiles)}
              onUpdateFile={(files) => createTaskStore.updateData({ attachedFiles: files })}
            />
          </div>
        </div>
        <div className={styles.buttonGroup}>
          {isValidRequest && !isValidWorkflow && !isQuestionPage && (
            <div className={styles.requirementsMsg}>
              <Typography
                variant="subtitle2"
                className={clsx({
                  [styles.errorLabel]: isSubmitDisabled,
                  [styles.warningLabel]: !isSubmitDisabled,
                })}
                tooltipText={`Automation requires the inputs highlighted in yellow are provided.`}
              >
                {'Automation requirements not met'}
              </Typography>
            </div>
          )}
          <div>
            <AsyncComponent
              component={Button}
              size="large"
              variant="primary"
              onClick={handleSubmitTicket}
              disabled={isSubmitDisabled}
              id={`${PAGE_NAME}_submitButton`}
              tooltipText={
                !userStore.currentUser.isContributor
                  ? NotificationText.createTicketPermissionWarning
                  : ''
              }
              style={{
                background:
                  isValidRequest && !isValidWorkflow && !isQuestionPage && !isSubmitDisabled
                    ? `${WARNING_COLOR} !important`
                    : '',
              }}
            >
              Submit
            </AsyncComponent>
            <Button
              size="large"
              variant="tertiary"
              onClick={handleCancel}
              id={`${PAGE_NAME}_cancelButton`}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>

      {quantityErrorOpen && (
        <ConfirmDialog
          open
          containerClassName={styles.errorMsgDialog}
          title=""
          description="Please input a lower quantity"
          okText="Got it"
          onOk={() => setQuantityErrorOpen(false)}
        />
      )}
    </div>
  );
};

export default withStore(observer(CreateForm));
