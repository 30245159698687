import { useMemo, useState } from 'react';
import { omit } from 'lodash';
import { GridActionsCellItem, GridRowEditStopReasons, GridRowModes } from '@mui/x-data-grid';

import { Typography, DataGridTable, Tooltip } from '@/components';
import { WARNING_COLOR, EDIT_DISABLED } from '@/utils/constants';
import { MuiIcons } from '@/utils/types';

import styles from './TableAttachment.module.css';

const PAGE_NAME = 'TableAttachment';

export default function TableAttachment({
  workflowType,
  tableData,
  onUpdateTable,
  label,
  attachment,
  showWarning,
  disabled,
}) {
  const showTableData = Boolean(tableData);
  const isWarning = showWarning && !showTableData;

  const [rowModesModel, setRowModesModel] = useState({});

  const handleEditClick = (id) => () => {
    setRowModesModel((oldModel) => ({ ...oldModel, [id]: { mode: GridRowModes.Edit } }));
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel((oldModel) => {
      return {
        ...oldModel,
        [id]: { mode: GridRowModes.View, isNew: false, showLoading: false },
      };
    });
  };

  const tableRowKeys = useMemo(() => (tableData ? Object.keys(tableData) : []), [tableData]);
  const rows = useMemo(
    () => tableRowKeys.map((key) => ({ ...tableData[key], id: key, Property: key })),
    [tableRowKeys, tableData],
  );
  const columns = useMemo(
    () =>
      attachment
        .map((item) => ({
          field: item,
          headerName: item,
          editable: item !== 'Property',
          type: item === 'Property' ? 'label' : 'number',
          width: 120,
          sortable: false,
        }))
        .concat(
          EDIT_DISABLED
            ? []
            : [
                {
                  field: 'actions',
                  type: 'actions',
                  headerName: 'Actions',
                  width: 100,
                  getActions: ({ id }) => {
                    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                    if (disabled) return [];

                    if (isInEditMode) {
                      return [
                        <Tooltip title="Save" arrow placement="top">
                          <GridActionsCellItem icon={MuiIcons.save} onClick={handleSaveClick(id)} />
                        </Tooltip>,
                      ];
                    }

                    return [
                      <Tooltip title="Edit" arrow placement="top">
                        <GridActionsCellItem icon={MuiIcons.pen} onClick={handleEditClick(id)} />
                      </Tooltip>,
                    ];
                  },
                },
              ],
        ),
    [attachment, rowModesModel, disabled],
  );

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleProcessRowUpdateError = (error) => {
    console.error('Error processing row update:', error);
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    if (!disabled) setRowModesModel(newRowModesModel);
  };

  const handleRowUpdate = (newRow) => {
    const newTableData = { ...tableData, [newRow.id]: omit(newRow, ['id', 'Property']) };
    onUpdateTable(newTableData);
    return newRow;
  };

  return (
    <div id={`${PAGE_NAME}_uploadTemplate_container`}>
      <div className={styles.row}>
        <Typography
          variant="subtitle2"
          sx={{ color: isWarning ? WARNING_COLOR : '' }}
          tooltipText={`Upload a CSV file with the ${workflowType} ${label} information.`}
        >
          {label}
        </Typography>
      </div>
      {tableRowKeys.length > 0 ? (
        <DataGridTable
          id={`${PAGE_NAME}_${label}`}
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowEditStop={handleRowEditStop}
          onRowModesModelChange={handleRowModesModelChange}
          processRowUpdate={handleRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          hideFooter
          disableSelectionOnClick
          className={styles.indent}
        />
      ) : null}
    </div>
  );
}
