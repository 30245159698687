import { orderBy } from 'lodash';
import { Config } from '../config';
import {
  getAssignFromId,
  convertLocalDateTime,
  getObjectType,
  getAmazonShortenStatus,
  formatLocalDateTime,
  getStatusFromId,
} from '../utils';
import { AssignType, AutomationStatusColors } from '@/utils/types';
import * as fetchWithAuth from './fetchWithAuth';
import { ATTACHMENTS_PREFIX } from '@/utils/constants';

function parseTicketFamily(obj) {
  const ticketPath = (obj.path ?? '')
    .split('/')
    .filter((id) => id)
    .map(Number);
  const ticketId = ticketPath[ticketPath.length - 1];
  const automation = obj.automation;
  return {
    id: ticketId,
    ticketId,
    title: obj.title || '',
    ticketPath,
    stateId: obj.stateId,
    state: getStatusFromId(obj.stateId),
    externalStatus: getAmazonShortenStatus(obj.externalState),
    workflow: obj.workflow,
    assignedTo: Number(obj.assignedTo || ''),
    automation: automation
      ? {
          name: automation,
          color: AutomationStatusColors[automation] || AutomationStatusColors.none,
        }
      : null,
  };
}

export function parseCaseMessage(message, assignOptions) {
  const assign = getAssignFromId(message.createdBy, assignOptions);
  const owner = message.externalBy || assign?.label || '';
  let type = assign?.type || AssignType.lightPurple;
  const name = owner.toLowerCase();
  if (message.externalBy) {
    if (name.includes('amazon')) type = AssignType.amazon;
    if (name.includes('helpdesk')) type = AssignType.helpdesk;
    if (name.includes('workflow')) type = AssignType.workflow;
  }

  const lastUpdated = message.externalOn ?? message.lastUpdated;

  return { ...message, assign: { ...assign, name: owner, type }, lastUpdated };
}

function parseTicketDetails(obj) {
  const ticket = obj.details;
  const familyTickets = obj.family.map(parseTicketFamily);
  const discussions = orderBy(
    (obj.discussions ?? []).filter((item) => !(item.message || '').startsWith(ATTACHMENTS_PREFIX)),
    [(o) => convertLocalDateTime(o.externalOn ?? o.createdOn)],
    'desc',
  );
  const attachmentsDiscussionId = (obj.discussions ?? []).find((item) =>
    item.message?.startsWith(ATTACHMENTS_PREFIX),
  )?.id;
  const attachments = (obj?.attachments || []).map((attachment) => ({
    id: attachment.id,
    ticketId: attachment.ticketId,
    discussionId: attachment.discussionId,
    name: attachment.attachment,
    url: attachment.uri,
    size: attachment.size,
  }));
  const ticketAttachments = attachments.filter(
    (attachment) => attachment.discussionId === attachmentsDiscussionId,
  );
  const ticketPath = (ticket.path ?? '')
    .split('/')
    .filter((id) => id)
    .map(Number);
  const config = JSON.parse(obj.config?.config);
  const result = JSON.parse(obj.result?.result ?? null);
  const { automation, automationTime, buildId } = ticket;

  const ticketInfo = {
    id: ticket.id,
    ticketId: ticket.id,
    ticketPath,
    organizationId: ticket.organizationId,
    parentId: ticket.parentId,
    title: ticket.title,
    description: ticket.description,
    stateId: ticket.stateId,
    externalId: ticket.externalId,
    externalStatus: getAmazonShortenStatus(ticket.externalState),
    priority: Number(ticket.priority),
    remainingWork: Number(ticket.quantity),
    createdById: Number(ticket.createdBy),
    ownedBy: Number(ticket.ownedBy),
    assignedTo: Number(ticket.assignedTo),
    commentedBy: Number(ticket.commentedBy),
    lastUpdated: convertLocalDateTime(ticket.updatedOn),
    resolvedDate: convertLocalDateTime(ticket.resolvedOn),
    createdDate: convertLocalDateTime(ticket.createdOn),
    estimatedCompletion: convertLocalDateTime(ticket.estimatedCompletion),
    workflow: ticket.workflow,
    objectType: getObjectType(ticket),
    automation: automation
      ? {
          name: automation,
          color: AutomationStatusColors[automation] || AutomationStatusColors.none,
          automationTime:
            automationTime && formatLocalDateTime(convertLocalDateTime(automationTime)),
          buildId,
          link: `https://dev.azure.com/WorkflowLabs/Helpdesk/_build/results?buildId=${buildId}&view=results`,
          buildError: ticket.buildError,
        }
      : null,
    config,
    attachmentsDiscussionId,
    attachments: ticketAttachments,
    discussions: discussions.map((discussion) => ({
      id: discussion.id,
      createdByName: discussion.createdByName,
      createdBy: Number(discussion.createdBy),
      createdDate: convertLocalDateTime(discussion.createdOn),
      lastUpdated: convertLocalDateTime(discussion.createdOn),
      externalBy: discussion.externalBy,
      externalOn: discussion.externalOn && convertLocalDateTime(discussion.externalOn),
      text: discussion.message || '',
      attachments: attachments.filter(({ discussionId }) => discussionId === discussion.id),
    })),
    completed: ticket.completed,
    result,
  };

  const scheduleList = (obj.scheduled || []).map((schedule, index) => ({
    id: schedule.id,
    ticketId: schedule.ticketId,
    cron: schedule.cron,
    label: schedule.label,
    paused: schedule.paused,
    lastRun: convertLocalDateTime(schedule.lastRun),
    createdOn: convertLocalDateTime(schedule.createdOn),
  }));

  return {
    ticketInfo,
    familyTickets,
    scheduleList,
    triggeredTickets:
      obj.triggered?.map((trigger) => ({
        ...trigger,
        automation: trigger.automation
          ? {
              name: trigger.automation,
              color: AutomationStatusColors[trigger.automation] || AutomationStatusColors.none,
            }
          : null,
      })) || [],
  };
}

export const getTicketDetails = async (ticketId) => {
  return await fetchWithAuth
    .get(`${Config.NEW_API}/Internal/tickets/${ticketId}`)
    .then((resp) => parseTicketDetails(resp));
};
