import styles from './EnvPage.module.css';

const EnvPage = () => {
  const envData = process.env || {};
  const envKeys = Object.keys(envData);
  return (
    <div className={styles.pageContainer}>
      <div className={styles.pageWrapper}>
        {envKeys.map((key) => (
          <div key={key} className={styles.itemWrapper}>
            <div className={styles.envKey}>
              {`${key}:`}
            </div>
            <div className={styles.envValue}>
              {envData[key] || ''}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EnvPage;
