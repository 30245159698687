import { createAuth0Client } from '@auth0/auth0-spa-js';
import { jwtDecode } from 'jwt-decode';
import { Config } from '../config';
import { getToken, saveToken } from '../utils';
import { APIError } from '../utils/errors';
import { userStore } from '../store';
import { StatusCode } from './constants';

export async function get(url, options = null) {
  return sendRequest(url, { ...options, method: 'GET' });
}

export async function post(url, body, options = null) {
  const headers = new Headers(options?.headers);
  headers.set('content-type', 'application/json');
  return sendRequest(url, {
    ...options,
    headers,
    body: JSON.stringify(body),
    method: 'POST',
  });
}

export async function postFormData(url, body, options = null) {
  const headers = new Headers(options?.headers);
  return sendRequest(url, { ...options, headers, body, method: 'POST' });
}

export async function put(url, body, options = null) {
  const headers = new Headers(options?.headers);
  headers.set('content-type', 'application/json');
  return sendRequest(url, {
    ...options,
    headers,
    body: JSON.stringify(body),
    method: 'PUT',
  });
}

export async function putFormData(url, body, options = null) {
  const headers = new Headers(options?.headers);
  return sendRequest(url, { ...options, headers, body, method: 'PUT' });
}

export async function del(url, options = null) {
  return sendRequest(url, { ...options, method: 'DELETE' });
}

export async function patch(url, body, options = null) {
  const headers = new Headers(options?.headers);
  headers.set('content-type', 'application/json');
  return sendRequest(url, {
    ...options,
    headers,
    body: JSON.stringify(body),
    method: 'PATCH',
  });
}

export const fetchDataWithToken = async () => {
  const domain = Config.REACT_APP_AUTH0_DOMAIN;
  const auth0 = await createAuth0Client({
    domain,
    client_id: Config.REACT_APP_AUTH0_CLIENT_ID,
  });

  try {
    const accessToken = await auth0.getTokenSilently({
      audience: `https://${domain}/api/v2/`,
      scope: 'read:current_user',
    });
    saveToken(accessToken);
    return accessToken;
  } catch {
    userStore.setIsUnAuthorizedError();
  }
};

export async function sendRequest(url, options = null) {
  let token = getToken();
  const currentTime = Math.ceil(Date.now() / 1000) + 300;
  if (token && token.trim() !== '') {
    let decodedJwt;
    try {
      decodedJwt = jwtDecode(token);
      if (decodedJwt.exp < currentTime) {
        token = await fetchDataWithToken();
      }
    } catch (error) {
      console.error('jwtDecode Error:', error);
      return;
    }
  }
  if (userStore.isUnAuthorizedError) return;
  const headers = new Headers(options?.headers);
  if (!options?.hasNoAuth) {
    headers.set('Authorization', `Bearer ${token}`);
  }
  userStore.errorAPIs[url] = null;

  return fetch(url, { method: options.method, body: options?.body, headers })
    .catch((error) => {
      const errorMessage = `Unexpected Error: ${error.message}`;
      return Promise.reject(new APIError(errorMessage, url, options?.body ?? '', error.stack));
    })
    .then(async (response) => {
      const statusCode = response.status;
      if (statusCode >= StatusCode.badRequest) {
        userStore.errorAPIs[url] = statusCode;
        if (statusCode === StatusCode.unauthorized) {
          userStore.setIsUnAuthorizedError();
          return;
        } else {
          const url = response.url;
          const statusText = `[${options.method}] Response with code ${statusCode} ( ${response.statusText} )`;
          const previewLineString = await response.text();
          const payload = options?.body ?? '';
          return Promise.reject(new APIError(statusText, url, payload, previewLineString));
        }
      } else {
        delete userStore.errorAPIs[url];
      }
      if (options?.isStream) return response;
      if (options?.isStatus) return statusCode;
      return options?.isPlainText ? await response.text() : await response.json();
    });
}
