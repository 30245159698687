import { jwtDecode } from 'jwt-decode';

const HideDownloadChromeBannerKey = 'hide_chrome_banner';
const RedirectURLAfterLogin = 'redirect_url_after_login';
const TOKEN_KEY = 'help-desk-token';
const ADMIN_SETTINGS_KEY = 'help-admin-settings';
const APP_LOADED = 'help-admin-loaded';

export const RESIZE_SAVE_KEY = 'help-admin';
export const TICKET_TREE_RESIZE_SAVE_KEY = 'help-admin-ticket-tree';
export const TICKET_DETAIL_RESIZE_SAVE_KEY = 'help-admin-ticket-details';
export const USERS_TABLE_RESIZE_SAVE_KEY = 'help-admin-users-page';
export const USERS_DETAILS_RESIZE_SAVE_KEY = 'help-admin-users-details';

export const isAppLoaded = () => {
  return Boolean(sessionStorage.getItem(APP_LOADED));
};

export const setAppLoaded = () => {
  sessionStorage.setItem(APP_LOADED, true);
};

export const clearAppLoaded = () => {
  sessionStorage.removeItem(APP_LOADED);
};

export const saveToken = (accessToken) => {
  localStorage.setItem(TOKEN_KEY, accessToken);
};

export const getToken = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  return token;
};

export const clearToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const getStoredAccessToken = (delta = 0) => {
  const token = getToken();

  if (token && token.trim() !== '') {
    let decodedJwt;
    try {
      decodedJwt = jwtDecode(token);
    } catch (error) {
      console.error('jwtDecode Error:', error);
      return null;
    }
    const currentTime = Math.floor(Date.now() / 1000);

    if (decodedJwt.exp && decodedJwt.exp > currentTime + delta) {
      return token;
    }
  }

  return null;
};

export const hideChromeBanner = () => {
  localStorage.setItem(HideDownloadChromeBannerKey, true);
};

export const isHideChromeBanner = () => {
  return Boolean(localStorage.getItem(HideDownloadChromeBannerKey));
};

export const saveRedirectURLAfterLogin = (url) => {
  localStorage.setItem(RedirectURLAfterLogin, url);
};

export const getRedirectURLAfterLogin = () => {
  return localStorage.getItem(RedirectURLAfterLogin);
};

export const removeRedirectURLAfterLogin = () => {
  return localStorage.removeItem(RedirectURLAfterLogin);
};

export const saveAdminSettings = (settings) => {
  localStorage.setItem(ADMIN_SETTINGS_KEY, JSON.stringify(settings));
};

export const getAdminAppSettings = () => {
  const appSettings = JSON.parse(localStorage.getItem(ADMIN_SETTINGS_KEY)) ?? {};
  return appSettings;
};
