import cx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Tooltip } from '@mui/material';

import Avatar from '../Avatar';

import { AvatarTooltip } from '@/components/Avatar/AvatarTooltip';

export const useStyles = makeStyles((theme) => ({
  assignContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    borderRadius: '8px',
    padding: '4px 8px',
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.neutrals.grayBlue,
    },
  },
  selectedContainer: {
    background: `${theme.palette.options.hover} !important`,
  },
  label: {
    flex: 1,
    padding: '0px 8px',
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: '0.15px',
    color: theme.palette.secondary.darkBlue,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  highlight: {
    fontWeight: 600,
    backgroundColor: theme.palette.secondary.lightPurple,
  },
  tooltip: {
    padding: '8px 13px 8px 8px !important',
    borderRadius: '12px !important',
    background: 'white !important',
    border: '1px solid #DDD',
    boxShadow: '0px 10px 10px 0px rgba(0, 0, 0, 0.10)',
  },
}));
const PAGE_NAME = 'AssignLabel';

const AssignLabel = ({
  isEditable,
  width,
  name,
  type,
  match,
  checked,
  email,
  borderColor,
  contentClassName,
  onChange,
}) => {
  const styles = useStyles();

  return (
    <Tooltip
      title={<AvatarTooltip name={name} email={email} variant={type} />}
      placement="left"
      classes={{ tooltip: styles.tooltip }}
    >
      <span
        id={`${PAGE_NAME}_span`}
        className={cx(
          styles.assignContainer,
          { [styles.selectedContainer]: checked },
          contentClassName,
        )}
        onClick={() => onChange && onChange(!checked)}
      >
        <Avatar
          compact
          id={`${PAGE_NAME}_ava`}
          name={name}
          variant={type}
          borderColor={borderColor || '#fff'}
          width={width}
          email={email}
        />
        <span className={styles.label}>
          {match && name ? (
            <>
              {name.slice(0, match[0])}
              <span className={styles.highlight}>{name.slice(match[0], match[1])}</span>
              {name.slice(match[1])}
            </>
          ) : (
            name
          )}
        </span>
      </span>
    </Tooltip>
  );
};

export default AssignLabel;
