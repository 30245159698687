import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { isEqual, omit } from 'lodash';
import { useMainStore } from './stores/useMainStore';
import TaskLog from './components/TaskLog';
import { getFiltersQuery, getLogsEndpointURL } from '@/utils';
import { BASE_ROUTES, UrlSearch } from '@/utils/types';
import { Config } from '@/config';
import { FilterType, MAX_QUERY_LENGTH } from '@/utils/constants';
import { LoadingIndicator } from '@/components';
import { useSettingsStore, userStore } from '@/store';
import { InitFilter } from './stores/FilterStore';

const MainPage = () => {
  const ticketsStore = useMainStore();
  const { filterStore, tableStore, taskLogStore, routerStore } = ticketsStore;
  const { accountStore } = useSettingsStore();
  const initLoadingRef = useRef(true);
  const prevState = useRef(null);
  const prevParams = useRef();
  const urlParams = routerStore.getSearchChunkParams();
  const statesLength = userStore.states?.length;

  useEffect(() => {
    return () => {
      ticketsStore.dispose();
      userStore.dispose();
    };
  }, []);

  useEffect(() => {
    filterStore.fetchFilters(accountStore.selectedAccount?.id);
  }, [accountStore.selectedAccount?.id]);

  useEffect(() => {
    if (routerStore.currentPage !== BASE_ROUTES.main) {
      routerStore.setCurrentPage(BASE_ROUTES.main);
      return;
    }
    if (prevParams.current && isEqual(prevParams.current, urlParams)) return;
    if (accountStore.allAccounts.length === 0) return;
    prevParams.current = urlParams;

    // NOTE: Update account
    const orgId = Number(urlParams[UrlSearch.organization]);
    if (orgId) {
      accountStore.onSelectAccount(orgId);
      accountStore.updateExpandedAccounts(
        accountStore.allAccounts,
        accountStore.selectedAccount?.path,
      );
    }

    // NOTE: Update table
    const pageNumber = Number(urlParams[UrlSearch.page]);
    const pageRows = Number(urlParams[UrlSearch.pageRows]);
    if (pageNumber && pageRows) {
      tableStore.setPageNumber(pageNumber);
      tableStore.setRowsPerPage(pageRows);
    }
    const sorts = urlParams[UrlSearch.sort]?.split('+');
    tableStore.setSort(sorts?.[0], sorts?.[1]);

    // NOTE: Update filters
    filterStore.updateAllSelectedValues({
      [FilterType.account]: urlParams[FilterType.account]?.split('+').map(Number) ?? [],
      [FilterType.status]: urlParams[FilterType.status]?.split('+').map(Number) ?? [],
      [FilterType.service]: urlParams[FilterType.service]?.split('+') ?? [],
      [FilterType.type]: urlParams[FilterType.type]?.split('+') ?? [],
      [FilterType.creator]: urlParams[FilterType.creator]?.split('+').map(Number) ?? [],
      [FilterType.owner]: urlParams[FilterType.owner]?.split('+').map(Number) ?? [],
      [FilterType.assign]: urlParams[FilterType.assign]?.split('+').map(Number) ?? [],
      [FilterType.automation]: urlParams[FilterType.automation]?.split('+') ?? [],
      [FilterType.error]: urlParams[FilterType.error]?.split('+') ?? [],
    });
    const searchTxt = urlParams[UrlSearch.search];
    filterStore.setSearch(searchTxt || '');
    initLoadingRef.current = false;
  }, [accountStore.allAccounts, urlParams]);

  useEffect(() => {
    if (initLoadingRef.current || !accountStore.selectedAccount?.id || !statesLength) return;

    // Update Uri
    const params = omit(
      routerStore.getSearchChunkParams(),
      Object.keys(InitFilter).concat(UrlSearch.search),
    );
    params[UrlSearch.organization] = accountStore.selectedAccount.id;
    params[UrlSearch.page] = tableStore.pageNumber;
    params[UrlSearch.pageRows] = tableStore.rowsPerPage;
    params[UrlSearch.sort] = `${tableStore.sortKey}+${tableStore.sortDirection}`;

    const filters = filterStore.filteredResult;
    Object.keys(filters).forEach((filterKey) => {
      const value = filters[filterKey]?.selectedValues;
      if (value?.length > 0) {
        params[filterKey] = value.join('+');
      }
    });
    if (filterStore.search) {
      params[UrlSearch.search] = filterStore.search;
    }

    const stringParams = Object.keys(params).reduce((acc, key) => {
      if (Number.isFinite(params[key])) return { ...acc, [key]: params[key].toString() };
      return { ...acc, [key]: params[key] };
    }, {});

    if (!isEqual(urlParams, stringParams)) {
      routerStore.setSearchChunkParams(params);
    }

    // Fetch service log
    const query = getFiltersQuery(
      tableStore.pageNumber,
      tableStore.rowsPerPage,
      filters,
      filterStore.search,
    );

    if (
      !prevState.current ||
      !isEqual(query, prevState.current.query) ||
      prevState.current.orgId !== accountStore.selectedAccount.id ||
      prevState.current.sortKey !== tableStore.sortKey ||
      prevState.current.sortDir !== tableStore.sortDirection
    ) {
      const orgId = accountStore.selectedAccount.id;
      prevState.current = {
        orgId,
        query,
        sortKey: tableStore.sortKey,
        sortDir: tableStore.sortDirection,
        search: filterStore.search,
      };
      const sort = { key: tableStore.sortKey, dir: tableStore.sortDirection };
      const url = getLogsEndpointURL(`${Config.NEW_API}/Ticket/log`, orgId, query, sort);
      if (url.length < MAX_QUERY_LENGTH) {
        taskLogStore.fetchTaskLog(orgId, query, sort);
      }
    }
  }, [
    statesLength,
    accountStore.selectedAccount,
    filterStore.filteredResult,
    filterStore.search,
    tableStore.pageNumber,
    tableStore.rowsPerPage,
    tableStore.sortKey,
    tableStore.sortDirection,
    urlParams,
  ]);

  if (!userStore.organizationLogo || !statesLength) {
    return <LoadingIndicator fullScreen />;
  }

  return <TaskLog />;
};

export default observer(MainPage);
