import PropTypes from 'prop-types';
import { IconButton as MuiIconButton } from '@mui/material';
import Tooltip from '../Tooltip';
import { useState } from 'react';

export const IconButton = ({ Icon, tooltipTitle, tooltipArrow, children, onClick, ...props }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <Tooltip
      title={tooltipTitle}
      arrow={tooltipArrow}
      placement='top'
      open={tooltipOpen}
      onOpen={() => setTooltipOpen(true)}
      onClose={() => setTooltipOpen(false)}
    >
      <MuiIconButton
        {...props}
        onClick={(e) => {
          if (onClick) {
            onClick(e);
          }
          setTooltipOpen(false);
        }}
      >
        {Icon && <Icon />}
        {children}
      </MuiIconButton>
    </Tooltip>
  );
};

IconButton.propTypes = {
  Icon: PropTypes.elementType,
  tooltipTitle: PropTypes.string,
  tooltipArrow: PropTypes.bool,
  children: PropTypes.node,
};

IconButton.defaultProps = {
  tooltipArrow: false,
  Icon: null,
  tooltipTitle: '',
  children: null,
};
