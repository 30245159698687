import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { IconButton } from '@mui/material';
import { Cached } from '@mui/icons-material';
import cx from 'clsx';

import {
  Button,
  CollapseButton,
  InfoRow,
  OrgAccessDropdown,
  ResizeGroupPanel,
  TextField,
  TriStateCheckbox,
  Typography,
  AsyncComponent,
} from '@/components';
import { isValidateEmail, showErrorNotification, USERS_DETAILS_RESIZE_SAVE_KEY } from '@/utils';
import { AnchorOrigin } from '@/utils/types';
import { CopyOutlineIcon } from '@/assets/icons';
import { userApi } from '@/api';
import { useSettingsStore } from '@/store';

import styles from './DetailContent.module.css';

const PAGE_NAME = 'UserContent';
const DEFAULT_STATICS_WIDTH = 132;

const UserContent = ({ isEditingMode, isSaving, setIsSaving, showError }) => {
  const { membersStore } = useSettingsStore();
  const [isCollapseDevSettings, setIsCollapseDevSettings] = useState(false);
  const editingMember = membersStore.firstSelectedUser;
  const {
    organizationId,
    first: firstName,
    last: lastName,
    email,
    authId,
    organizationPathByName,
    createdOn,
    activeRequests,
    assignedTickets,
    waitingQuestions,
  } = editingMember;
  const { enabled: devModeEnabled, accessId, secret } = membersStore.accessInfo;
  const { isActive, isAdmin, isAssignable, isContributor, emailsEnabled } = membersStore.unionInfo;
  const isMultiple = membersStore.selectedUsers.length > 1;

  const handleChangeMemberInfo = (fieldValue) => {
    if (isMultiple) {
      membersStore.updateUnionInfo(fieldValue);
    } else {
      membersStore.updateSelectedUser(fieldValue);
    }
  };

  const handleCopyText = (text) => {
    navigator.clipboard.writeText(text);
  };

  const handleChangeEmailEnabled = (emailsEnabled) => {
    if (isMultiple) {
      membersStore.updateUnionInfo({ emailsEnabled });
    } else {
      membersStore.updateSelectedUser({
        profile: { ...editingMember.profile, emailsEnabled },
      });
    }
  };

  const handleRefreshAccess = async () => {
    setIsSaving(true);
    try {
      const access = await userApi.refreshAPIAccess(editingMember.id);
      membersStore.setAccessInfo(access);
    } catch (err) {
      showErrorNotification(err.preview ?? err.message);
    }
    setIsSaving(false);
  };

  const handleToggleAccess = async () => {
    setIsSaving(true);
    try {
      const access = await userApi.toggleAPIAccess(editingMember.id, !devModeEnabled);
      membersStore.setAccessInfo(access);
    } catch (err) {
      showErrorNotification(err.preview ?? err.message);
    }
    setIsSaving(false);
  };

  return (
    <div className={styles.detailsContainer}>
      <ResizeGroupPanel
        saveId={USERS_DETAILS_RESIZE_SAVE_KEY}
        direction="horizontal"
        initialFirstSize={80}
        classes={{ root: styles.panel }}
        firstPanel={
          <>
            {!isMultiple && (
              <>
                {isEditingMode && (
                  <>
                    <InfoRow label="Name">
                      <div className={cx(styles.row, styles.fullName)}>
                        <TextField
                          key="First Name"
                          placeholder="First Name"
                          value={firstName}
                          error={showError && !firstName}
                          onChange={(e) => handleChangeMemberInfo({ first: e.target.value })}
                          id={`${PAGE_NAME}_newMember_firstName`}
                          disabled={!isEditingMode}
                        />
                        <TextField
                          key="Last Name"
                          placeholder="Last Name"
                          value={lastName}
                          error={showError && !lastName}
                          onChange={(e) => handleChangeMemberInfo({ last: e.target.value })}
                          id={`${PAGE_NAME}_newMember_lastName`}
                        />
                      </div>
                    </InfoRow>
                  </>
                )}
                <InfoRow label="Organization">
                  {!isEditingMode && (
                    <Typography variant="body2" className={styles.path}>
                      {organizationPathByName}
                    </Typography>
                  )}
                  {isEditingMode && (
                    <OrgAccessDropdown
                      value={organizationId}
                      anchorPosition={AnchorOrigin.bottom}
                      error={showError && !organizationId}
                      className={styles.orgDropdown}
                      onChange={(organizationId, organizationPathByName) =>
                        handleChangeMemberInfo({
                          organizationId,
                          organizationPathByName,
                        })
                      }
                      style={{ width: '100%' }}
                    />
                  )}
                </InfoRow>
                <InfoRow label="Work Email">
                  {!isEditingMode ? (
                    <Typography variant="body2">{email}</Typography>
                  ) : (
                    <TextField
                      key="Work Email"
                      placeholder="Work Email"
                      value={email}
                      error={showError && !isValidateEmail(email)}
                      className={styles.fullName}
                      onChange={(e) => handleChangeMemberInfo({ email: e.target.value })}
                      id={`${PAGE_NAME}_newMember_workEmail`}
                      disabled={!isEditingMode}
                    />
                  )}
                </InfoRow>
                <InfoRow label="Created">
                  <Typography variant="body2">{createdOn}</Typography>
                </InfoRow>
                <InfoRow label="AuthId">
                  <Typography variant="body2">{authId}</Typography>
                </InfoRow>
              </>
            )}
            <InfoRow label="Is Active">
              <TriStateCheckbox
                triState={isMultiple}
                checked={isActive}
                onChange={(value) => handleChangeMemberInfo({ isActive: value })}
                disabled={!isEditingMode}
              />
            </InfoRow>
            <InfoRow label="Is Admin">
              <TriStateCheckbox
                triState={isMultiple}
                checked={isAdmin}
                onChange={(value) => handleChangeMemberInfo({ isAdmin: value })}
                disabled={!isEditingMode}
              />
            </InfoRow>
            <InfoRow label="Is Assignable">
              <TriStateCheckbox
                triState={isMultiple}
                checked={isAssignable}
                onChange={(value) => handleChangeMemberInfo({ isAssignable: value })}
                disabled={!isEditingMode}
              />
            </InfoRow>
            <InfoRow label="Is Contributor">
              <TriStateCheckbox
                triState={isMultiple}
                checked={isContributor}
                onChange={(value) => handleChangeMemberInfo({ isContributor: value })}
                disabled={!isEditingMode}
              />
            </InfoRow>
            <InfoRow label="Email Enabled">
              <TriStateCheckbox
                triState={isMultiple}
                checked={emailsEnabled}
                disabled={!isEditingMode}
                onChange={(value) => handleChangeEmailEnabled(value)}
              />
            </InfoRow>
            {!isMultiple && (
              <Typography variant="caption-bold" className={styles.row}>
                Developer Mode
                <Button
                  size="small"
                  variant="secondary"
                  onClick={handleToggleAccess}
                  disabled={isSaving || !isEditingMode}
                >
                  {devModeEnabled ? 'Disable' : 'Enable'}
                </Button>
                {devModeEnabled && (
                  <CollapseButton
                    isCollapse={isCollapseDevSettings}
                    onClick={() => setIsCollapseDevSettings(!isCollapseDevSettings)}
                  />
                )}
              </Typography>
            )}
            {devModeEnabled && !isMultiple && !isCollapseDevSettings && (
              <>
                <InfoRow label="AccessId">
                  <TextField value={accessId} sx={{ flex: 1 }} inactive disabled />
                  <IconButton
                    id={`${PAGE_NAME}_copyButton`}
                    onClick={() => handleCopyText(accessId)}
                  >
                    <CopyOutlineIcon fontSize="small" />
                  </IconButton>
                  <div style={{ width: 30 }} />
                </InfoRow>
                <InfoRow label="Secret">
                  <TextField value={secret} sx={{ flex: 1 }} inactive disabled />
                  <IconButton id={`${PAGE_NAME}_copyButton`} onClick={() => handleCopyText(secret)}>
                    <CopyOutlineIcon fontSize="small" />
                  </IconButton>
                  <AsyncComponent
                    component={IconButton}
                    id={`${PAGE_NAME}_refreshButton`}
                    onClick={handleRefreshAccess}
                  >
                    <Cached fontSize="small" className={styles.icon} />
                  </AsyncComponent>
                </InfoRow>
              </>
            )}
          </>
        }
      >
        <InfoRow label="Active Requests" labelWidth={DEFAULT_STATICS_WIDTH}>
          <Typography variant="body2">{activeRequests}</Typography>
        </InfoRow>
        <InfoRow label="Assigned Tickets" labelWidth={DEFAULT_STATICS_WIDTH}>
          <Typography variant="body2">{assignedTickets}</Typography>
        </InfoRow>
        <InfoRow label="Waiting Questions" labelWidth={DEFAULT_STATICS_WIDTH}>
          <Typography variant="body2">{waitingQuestions}</Typography>
        </InfoRow>
      </ResizeGroupPanel>
    </div>
  );
};

export default observer(UserContent);
