import { last, trim, isArray, isObject, mapKeys, mapValues, camelCase } from 'lodash';
import {
  DEFAULT_DAYTIME_FORMAT,
  convertLocalDateTime,
  formatLocalDateTime,
  getObjectType,
  getStatus,
  getStatusFromId,
  getTicketLink,
} from '../utils';
import { MemberStatusList, getFullName } from '../utils/users';
import { AutomationStatusColors, Unassigned } from '@/utils/types';

export function parseUser(obj) {
  const name = obj.name?.trim();
  const organizationId =
    obj.organizationId || Number(last(obj.organizationPath.split('/').filter((id) => id)));
  const organizationName = last(obj.organizationPathByName?.split('/')?.filter((name) => name));
  const status = MemberStatusList.filter(({ key }) => obj[key]).map(({ label }) => label);

  if (name.startsWith(Unassigned.name)) {
    Unassigned.id = obj.id;
    Unassigned.value = obj.id;
    Unassigned.name = name;
    Unassigned.organizationId = organizationId;
    Unassigned.organizationName = organizationName;
    Unassigned.status = status;
  }

  return {
    id: obj.id,
    authId: obj.authId,
    email: obj.email,
    emailsEnabled: obj.profile?.emailsEnabled ?? obj.emailsEnabled,
    first: obj.first,
    last: obj.last,
    name: name ?? getFullName(obj),
    image: obj.image,
    organizationId,
    organizationName,
    organizationPath: obj.organizationPath,
    organizationPathByName: obj.organizationPathByName,
    status,
    isActive: obj.isActive,
    isAdmin: obj.isAdmin,
    isContributor: obj.isContributor,
    isAssignable: obj.isAssignable,
    pinnedTickets: obj.profile?.pinnedTickets ?? [],
    pinnedRequests: obj.profile?.pinnedRequests ?? [],
    helpItemTags: obj.helpItemTags ?? [],
    createdOn: formatLocalDateTime(obj.createdOn, DEFAULT_DAYTIME_FORMAT),
    filters: obj.profile?.filters ?? obj.filters ?? {},
    ticketColumns: obj.profile?.ticketColumns,
    profile: obj.profile,
    accessId: obj.accessId,
  };
}

export function parseUserFromObj(obj) {
  return {
    id: obj.id,
    email: obj.email,
    first: obj.first,
    last: obj.last,
    name: `${obj.first} ${obj.last}`,
    organizationId: obj.organizationId,
    organizationPath: obj.organizationPath,
    profile: obj.profile,
    isActive: obj.isActive,
    isAdmin: obj.isAdmin,
    isContributor: obj.isContributor,
    isAssignable: obj.isAssignable,
  };
}

export function parseTaskLogFromResponse(obj) {
  const { ticketId, automation } = obj;
  const organizationId = obj.organizationId;
  const workflow = obj.workflow;

  return {
    id: ticketId,
    ticketId,
    row: obj.row,
    automation: automation
      ? {
          name: automation,
          color: AutomationStatusColors[automation] || AutomationStatusColors.none,
        }
      : null,
    ticketUrl: getTicketLink(ticketId),
    workflow,
    organizationId,
    objectType: getObjectType(obj),
    intentType: obj.workflow,
    state: getStatusFromId(obj.stateId),
    title: obj.title,
    createdBy: Number(obj.createdBy),
    assignedTo: Number(obj.assignedTo),
    ownedBy: Number(obj.ownedBy),
    createdDate: convertLocalDateTime(obj.createdOn),
    lastUpdated: convertLocalDateTime(obj.updatedOn),
    requestedDate: convertLocalDateTime(obj.requestedByDate),
    error: obj.error,
  };
}

export function parseBulkTaskLogFromResponse(obj) {
  return {
    id: obj.id,
    row: obj.row,
    ticketUrl: getTicketLink(obj.id),
    workflow: obj.workflow,
    path: obj.path,
    organizationPath: obj.organizationPath,
    ticketId: obj.id,
    organization: obj.organization,
    accountId: obj.organizationId,
    state: getStatus(obj.state),
    title: obj.title,
    ownedByName: trim(obj.ownedByName || obj.createdByName || ''),
    assignedToName: trim(obj.assignedToName || ''),
    commentedByName: obj.commentedByName,
    resolved: obj.resolved,
    total: obj.total,
    lastUpdated: obj.lastUpdated,
  };
}

export function deepCamelCaseKeys(obj) {
  if (isArray(obj)) {
    return obj.map(deepCamelCaseKeys);
  } else if (isObject(obj)) {
    return mapKeys(mapValues(obj, deepCamelCaseKeys), (v, k) => camelCase(k));
  }
  return obj;
}
