import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Popover, TextField } from '@mui/material';
import { AutoSizer, List } from 'react-virtualized';

import Typography from '../Typography';
import { useStyles } from './styles';
import Button from '../Button';

const PAGE_NAME = 'MultiSelect';

const MultiSelect = ({
  size,
  value: values,
  options,
  isDarkTheme,
  isSearchBar,
  hasSelectAllClear,
  hasClear,
  placeholder,
  MenuProps,
  renderValue,
  MenuItemComponent,
  onChange,
  disabled,
  anchorOrigin,
  transformOrigin,
  classes,
  handleOpenPopover,
  ...rest
}) => {
  const styles = useStyles();
  const [search, setSearch] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const valueText = useMemo(() => {
    if (renderValue) return renderValue(values, placeholder);
    let firstLabel = options.find((item) => item.value === values[0])?.label ?? '';
    firstLabel = `${firstLabel.slice(0, 15)}${firstLabel.length > 15 ? '...' : ''}`;
    let valueLabel;
    if (values.length === 0 && placeholder) {
      valueLabel = placeholder;
    } else if (values.length > 1) {
      valueLabel = `${firstLabel} +${values.length - 1} more`;
    } else {
      valueLabel = firstLabel;
    }
    return valueLabel;
  }, [values, placeholder, options]);

  const { filteredOptions, selectedFilteredOptions } = useMemo(() => {
    const filteredOptions = [];
    const selectedFilteredOptions = [];

    options.forEach((option) => {
      const checked = values.includes(option.value);
      if (isSearchBar && checked) {
        selectedFilteredOptions.push({ ...option, checked, match: [0, 0] });
      } else if (search) {
        const value = option.label;
        const startIndex = value?.toLowerCase().indexOf(search.toLowerCase().trim());
        if (startIndex !== -1) {
          filteredOptions.push({
            ...option,
            checked,
            match: [startIndex, startIndex + search.length],
          });
        }
      } else {
        filteredOptions.push({ ...option, checked, match: [0, 0] });
      }
    });
    return { filteredOptions, selectedFilteredOptions };
  }, [options, values, search]);

  const handleOpen = (target) => {
    setAnchorEl(target);
    handleOpenPopover(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearch('');
    handleOpenPopover(false);
  };

  const handleChange = (value, changedItem) => {
    onChange(value, changedItem);
  };

  const handleDropDownKeyDownProcess = (event) => {
    if (disabled) return;
    if (event.keyCode === 13 || event.keyCode === 32) {
      event.stopPropagation();
      handleOpen(event.currentTarget);
    }
  };

  const rowRenderer = ({ key, index, style }) => {
    const { value, label, match, checked } = filteredOptions[index];
    return (
      <MenuItem
        key={key}
        value={value}
        style={{ ...style, paddingLeft: 0 }}
        onClick={() => {
          handleChange(!checked ? values.concat(value) : values.filter((o) => o !== value), value);
        }}
      >
        <MenuItemComponent label={label} checked={checked} match={match} />
      </MenuItem>
    );
  };

  return (
    <>
      <div
        id={rest.id}
        tabIndex={0}
        role="button"
        onKeyDown={handleDropDownKeyDownProcess}
        className={cx(
          styles.container,
          {
            [styles.selected]: Boolean(anchorEl),
            [styles.selectedContainer]: values.length > 0,
            [styles.disabled]: disabled,
            [styles.smallContainer]: size === 'small',
          },
          classes?.root,
        )}
        onClick={(event) => {
          if (!disabled) handleOpen(event.currentTarget);
        }}
      >
        <Typography
          id={`${PAGE_NAME}_selectedValue`}
          variant={size === 'small' ? 'body2' : 'body1'}
          style={{ whiteSpace: 'nowrap', minWidth: '60px' }}
        >
          {valueText}
        </Typography>
        <div className={cx(styles.moreIcon, { [styles.open]: Boolean(anchorEl) })}>
          <ExpandMoreIcon />
        </div>
      </div>

      <Popover
        anchorEl={anchorEl}
        id={`${PAGE_NAME}_Popover`}
        open={Boolean(anchorEl)}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={handleClose}
        classes={{ paper: cx(styles.paper, classes?.paper) }}
      >
        <div className={styles.popoverContent}>
          <div className={styles.buttonContainer}>
            <Typography variant="subtitle1">{placeholder}</Typography>
            {hasClear && filteredOptions.length > 0 && (
              <Button
                id="btn-clear-options"
                size="small"
                variant="tertiary"
                disabled={!values.length}
                onClick={() => handleChange([])}
              >
                Clear
              </Button>
            )}
          </div>
          {isSearchBar && (
            <div className={cx(styles.menuContainer, styles.selectedList)}>
              {selectedFilteredOptions.map(({ value, label, match, checked }) => (
                <MenuItem
                  value={value}
                  classes={{ root: styles.menuItem }}
                  onClick={(e) => {
                    handleChange(
                      !checked ? values.concat(value) : values.filter((o) => o !== value),
                      value,
                    );
                  }}
                >
                  <MenuItemComponent label={label} checked={checked} match={match} />
                </MenuItem>
              ))}
            </div>
          )}
          {isSearchBar && (
            <TextField
              value={search}
              placeholder="Search"
              autoFocus
              classes={{ root: styles.searchRoot }}
              InputProps={{
                classes: {
                  root: styles.inputRoot,
                  input: styles.inputInput,
                  notchedOutline: styles.inputNotchedOutline,
                  focused: styles.inputFocused,
                },
              }}
              onChange={(e) => {
                e.stopPropagation();
                setSearch(e.target.value);
              }}
            />
          )}
          {hasSelectAllClear && filteredOptions.length > 0 && (
            <div className={styles.buttonContainer}>
              <Button
                id="btn-selectall-options"
                size="small"
                variant="tertiary"
                classes={{ root: styles.button }}
                onClick={() => handleChange(filteredOptions.map(({ value }) => value))}
              >
                Select All
              </Button>
              <Button
                id="btn-clear-options"
                size="small"
                variant="tertiary"
                disabled={!selectedFilteredOptions.length}
                classes={{ root: styles.button }}
                onClick={() => handleChange([])}
              >
                Clear
              </Button>
            </div>
          )}
          <AutoSizer disableHeight>
            {({ width }) => (
              <List
                width={width}
                height={200}
                rowCount={filteredOptions.length}
                rowHeight={32}
                rowRenderer={rowRenderer}
              />
            )}
          </AutoSizer>
        </div>
      </Popover>
    </>
  );
};

MultiSelect.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  value: PropTypes.arrayOf(PropTypes.any),
  isSearchBar: PropTypes.bool,
  isDarkTheme: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.any, label: PropTypes.string })),
  placeholderColor: PropTypes.string,
  hasSelectAllClear: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  transformOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  handleOpenPopover: PropTypes.func,
};

MultiSelect.defaultProps = {
  size: 'small',
  value: [],
  isSearchBar: false,
  isDarkTheme: false,
  placeholder: '',
  options: [],
  placeholderColor: '',
  hasSelectAllClear: false,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  handleOpenPopover: () => {},
};

export default MultiSelect;
