import { MuiIcons } from '@/utils/types';
import './CollapseButton.css';

export const CollapseButton = ({ isCollapse, onClick }) => {
  return (
    <div className="collapseButton" onClick={onClick}>
      {isCollapse ? MuiIcons.up : MuiIcons.down}
    </div>
  );
};
