export const MAX_QUESTION_LENGTH = 250;
export const DEFAULT_ITEM_HEIGHT = 28;
export const PLATFORM_ITEM_WIDTH = 312;

export const ATTACHMENTS_FILED = 'attachments';
export const ATTACHMENTS_PREFIX = '[TicketId=';
export const EDIT_DISABLED = true;

export const ACCEPT_TEMPLATE_FILES =
  'text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const FeedbackLink = 'https://form.typeform.com/to/IOW0VuJj';

export const WARNING_COLOR = '#D7A100';

export const MIN_BULK_UPDATED_DAYS = 4;

export const OPERATION_TYPES = {
  new: 'New',
  update: 'Update',
};
export const ORG_OPERATION_OPTIONS = [
  {
    label: 'Create organization',
    value: OPERATION_TYPES.new,
  },
  {
    label: 'Update organization',
    value: OPERATION_TYPES.update,
  },
];

export const DEFAULT_KEYWORD_INFO = {
  Asin: '',
  ProductType: '',
};

export const CASE_OPERATION_TYPES = {
  create: 'Create',
  get: 'Get',
};

export const CASE_OPERATION_OPTIONS = [
  {
    label: 'Track a case',
    value: CASE_OPERATION_TYPES.get,
  },
  {
    label: 'Create a case',
    value: CASE_OPERATION_TYPES.create,
  },
];

export const CREATE_CASE_TYPES = {
  updateProduct: 'UpdatePdp',
  updateVariation: 'UpdateVariation',
  removeVariation: 'RemoveVariation',
};

export const CREATE_CASE_OPTIONS = [
  {
    value: CREATE_CASE_TYPES.updateProduct,
    label: 'Update Product Detail Page',
  },
  {
    value: CREATE_CASE_TYPES.updateVariation,
    label: 'Update Product Variation',
  },
  {
    value: CREATE_CASE_TYPES.removeVariation,
    label: 'Remove Product Variation',
  },
];

export const FilterType = {
  searchType: 'searchType',
  captcha: 'captcha',
  platform: 'platform',
  account: 'account',
  status: 'status',
  creator: 'creator',
  owner: 'owner',
  assign: 'assign',
  service: 'service',
  type: 'isRequest',
  viewBy: 'viewBy',
  createdAfter: 'createdAfter',
  updatedAfter: 'updatedAfter',
  automation: 'automation',
  organizationId: 'organizationId',
  lastUpdated: 'lastUpdated',
  activeChildren: 'activeChildren',
  error: 'error',
};

export const loadingImg = 'https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg';

export const CONFIG_TABS = {
  form: 'Form',
  json: 'Json',
  configuration: 'Configuration',
  results: 'Results',
};

export const MAX_QUERY_LENGTH = 2048;
export const MAX_QUANTITY = 100000;

export const SECOND = 1000;
export const SESSION_TIMEOUT = 10000;

export const PAGE_MODE = {
  none: 0,
  loading: 1,
  editing: 2,
  creating: 3,
};

export const DEFAULT_ORGANIZATION = {
  name: 'New Organization',
  isActive: true,
  profile: {
    branding: { isLogoChanged: false },
    logins: [],
    eventSubscriptions: [],
    settings: {},
    services: [],
  },
};

export const DEFAULT_PRODUCT_INFO = {
  asin: '',
  title: '',
  brand: '',
  description: '',
  isValid: false,
};

export const SearchTypes = {
  name: 'name',
  path: 'path',
};

const OrgsColumnType = {
  id: 'id',
  name: 'name',
  platforms: 'orgPlatformLabels',
  createdOn: 'createdOn',
  captcha: 'captcha',
};

export const OrganizationFilters = {
  [FilterType.searchType]: {
    key: OrgsColumnType.name,
    label: 'Search by',
    values: [
      { value: SearchTypes.name, label: 'Search by name' },
      { value: SearchTypes.path, label: 'Search by path' },
    ],
    selectedValues: [SearchTypes.path],
  },
  [FilterType.platform]: {
    key: OrgsColumnType.platforms,
    label: 'Platform',
    values: [],
    selectedValues: [],
  },
  [FilterType.captcha]: {
    key: OrgsColumnType.captcha,
    label: 'Captcha',
    values: [{ value: 'captcha', label: 'Is Captcha' }],
    selectedValues: [],
  },
};

export const DEFAULT_SCHEDULE = {
  cron: '0 13 * * 1',
  ticketId: '',
  paused: false,
};

const cronMonthStringFunc = (month, interval) => {
  let curMonth = month % interval;
  if (curMonth === 0) curMonth = interval;
  let cronStr = String(curMonth);
  curMonth = curMonth + interval;
  while (curMonth <= 12) {
    cronStr = cronStr + ',' + String(curMonth);
    curMonth = curMonth + interval;
  }
  return cronStr;
};

export const DEFAULT_CRONS = [
  { value: '0 13 * * *', label: 'Daily', isCronValue: true },
  {
    value: 'everyWeek',
    label: 'Every Week on X Day',
    cronFunc: ({ dayOfWeek }) => `0 13 * * ${dayOfWeek}`,
  },
  { value: '0 13 * * 2,4', label: 'T, Th', isCronValue: true },
  { value: '0 13 * * 1,3,5', label: 'M, W, F', isCronValue: true },
  { value: '0 13 * * 1-5', label: 'M, T, W, Th, F', isCronValue: true },
  {
    value: 'everyMonth',
    label: 'Every month on X Day',
    cronFunc: ({ day }) => `0 13 ${day} * *`,
  },
  {
    value: 'everyThreeMonths',
    label: 'Every 3 months on X Day',
    cronFunc: ({ day, month }) => `0 13 ${day} ${cronMonthStringFunc(month, 3)} *`,
  },
  {
    value: 'everySixMonths',
    label: 'Every 6 months on X Day',
    cronFunc: ({ day, month }) => `0 13 ${day} ${cronMonthStringFunc(month, 6)} *`,
  },
  {
    value: 'everyYear',
    label: 'Every 12 months on X Day',
    cronFunc: ({ day, month }) => `0 13 ${day} ${month} *`,
  },
];

export const SCHEDULE_MODE = {
  none: '',
  edit: 'edit',
  create: 'create',
};

export const AVATAR_COLORS = [
  {
    color: '#FFFFFF',
    bgColor: '#DD6C6C',
  },
  {
    color: '#FFFFFF',
    bgColor: '#4164DF',
  },
  {
    color: '#242424',
    bgColor: '#83DD6C',
  },
  {
    color: '#242424',
    bgColor: '#DCDF41',
  },
  {
    color: '#FFFFFF',
    bgColor: '#DF41BC',
  },
  {
    color: '#FFFFFF',
    bgColor: '#EA8209',
  },
  {
    color: '#FFFFFF',
    bgColor: '#1D9113',
  },
  {
    color: '#242424',
    bgColor: '#CCA5D6',
  },
  {
    color: '#FFFFFF',
    bgColor: '#DF4141',
  },
  {
    color: '#242424',
    bgColor: '#CCCCDF',
  },
  {
    color: '#FFFFFF',
    bgColor: '#911313',
  },
  {
    color: '#FFFFFF',
    bgColor: '#DF9641',
  },
  {
    color: '#FFFFFF',
    bgColor: '#B26CDD',
  },
  {
    color: '#242424',
    bgColor: '#41DF93',
  },
  {
    color: '#242424',
    bgColor: '#A5ACD6',
  },
  {
    color: '#FFFFFF',
    bgColor: '#8741DF',
  },
  {
    color: '#242424',
    bgColor: '#6CC2DD',
  },
  {
    color: '#242424',
    bgColor: '#D6A5A5',
  },
];
