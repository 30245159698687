import { useState } from 'react';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import cx from 'clsx';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  HelpOutline as HelpOutlineIcon,
} from '@mui/icons-material';
import queryString from 'query-string';

import { useSettingsStore, userStore } from '@/store';
import { theme } from '@/config';
import { FeedbackLink } from '@/utils/constants';
import { Typography, TextLink, Tooltip, Icon, Avatar } from '@/components';
import { isMobileDetected } from '@/utils';
import { BASE_ROUTES } from '@/utils/types';
import { SettingsPopover } from './SettingsPopover';
import { AvatarTooltip } from '@/components/Avatar/AvatarTooltip';

import styles from './TopNavigation.module.css';

const LINK_TABS = [
  { title: 'Tickets', link: BASE_ROUTES.main },
  { title: 'Organizations', link: BASE_ROUTES.organizations },
  { title: 'Users', link: BASE_ROUTES.users },
  { title: 'Bulk', link: BASE_ROUTES.bulk },
];

const PAGE_NAME = 'topNavigation';

const TopNavigation = () => {
  const { accountStore, routerStore } = useSettingsStore();
  const [settingAnchorEl, setSettingAnchorEl] = useState(null);

  const currentUser = userStore.currentUser;
  const navigate = useNavigate();

  const handleOpenPopupMenu = (event) => {
    setSettingAnchorEl(event.currentTarget);
  };

  const handleClick = (e, pageLink) => {
    e.preventDefault();
    const searchUrl =
      pageLink !== routerStore.currentPage ? routerStore.lastPageLink(pageLink) : '';
    const newUrl = queryString.stringify({
      ...queryString.parse(searchUrl),
      organization: accountStore.selectedAccount?.id,
    });
    navigate(`${pageLink}?${newUrl}`);
  };

  return (
    <>
      <div className={styles.topNavigationLayout}>
        <div className={styles.topNavigationContainer}>
          <a
            tabIndex={0}
            className={styles.link}
            onClick={(e) => handleClick(e, BASE_ROUTES.main)}
            href={`${BASE_ROUTES.main}`}
            data-testid={`${PAGE_NAME}_logo`}
          >
            <div
              className={cx(styles.logoIconWrapper, {
                [styles.mobileLogoWrapper]: isMobileDetected,
              })}
              id={`${PAGE_NAME}logoIcon`}
            >
              {userStore.organizationLogo && (
                <img src={userStore.organizationLogo} className={styles.logoIcon} alt="logo" />
              )}
            </div>
          </a>
          <div className={styles.role}>Admin</div>
          <span className={styles.versionLabel}>
            {userStore.version && `v${userStore.version}`}
          </span>
          <div className={styles.subAppsGroup}>
            {LINK_TABS.map(({ title, link }) => (
              <a
                key={title}
                tabIndex={0}
                className={styles.link}
                onClick={(e) => handleClick(e, link)}
                href={link}
                data-testid={`${PAGE_NAME}_tab${title}`}
              >
                <Typography className={styles.subAppTitle} id={`${PAGE_NAME}_${title}`}>
                  {title}
                </Typography>
                <div
                  className={cx(styles.subAppTitleUnderline, {
                    [styles.hide]: window.location.pathname !== link,
                  })}
                />
              </a>
            ))}
          </div>

          <div className={styles.actionsWrapper}>
            <div className={styles.settingsContainer}>
              <TextLink tabIndex="-1" href={FeedbackLink} data-testid={`${PAGE_NAME}_feedback`}>
                <Tooltip tabIndex={0} title="Give Feedback" arrow placement="top">
                  <IconButton id={`${PAGE_NAME}_feedbackIconButton`}>
                    <Icon type="feedback" width={18} height={19} />
                  </IconButton>
                </Tooltip>
              </TextLink>
              <TextLink tabIndex="-1" href={userStore.organizationHelpLink} data-testid={`${PAGE_NAME}_help`}>
                <Tooltip tabIndex={0} title="Help" arrow placement="top">
                  <IconButton id={`${PAGE_NAME}_helpIconButton`}>
                    <HelpOutlineIcon color={`${theme.palette.neutrals.greyMed}`} />
                  </IconButton>
                </Tooltip>
              </TextLink>
              <div
                tabIndex={0}
                className={styles.settingsGroup}
                id={`${PAGE_NAME}_settingsGroup`}
                onClick={handleOpenPopupMenu}
                data-testid={`${PAGE_NAME}_settingsGroup`}
              >
                <Avatar
                  name={currentUser.name}
                  variant={currentUser.type}
                  className={styles.avatar}
                  email={currentUser.email}
                  tooltipText={
                    <AvatarTooltip
                      name={currentUser.name}
                      email={currentUser.email}
                      variant={currentUser.type}
                    />
                  }
                />
                <Typography className={styles.nameLabel} variant="subtitle1">
                  {currentUser.first}
                </Typography>
                {Boolean(settingAnchorEl) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </div>
            </div>
          </div>
        </div>

        <SettingsPopover anchorEl={settingAnchorEl} handleClose={() => setSettingAnchorEl(null)} />
      </div>
    </>
  );
};

export default observer(TopNavigation);
