import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { cloneDeep } from 'lodash';
import { organizationApi } from '../api';
import { setError } from '../utils/errors';
import { FilterType, OrganizationFilters, SearchTypes } from '../utils/constants';
import { userStore } from './UserStore';
import { BASE_ROUTES } from '@/utils/types';

class AccountStore {
  filters = cloneDeep(OrganizationFilters);
  search = '';
  allAccounts = [];
  accounts = [];
  isLoading = true;
  selectedAccount = {};
  arrangedAccounts = null;
  isExpandedAll = true;

  constructor() {
    makeObservable(this, {
      allAccounts: observable,
      childAccounts: computed,
      filteredAccounts: computed,
      filteredOrganizations: computed,
      updateExpandedAccounts: action,

      search: observable,
      setSearch: action,

      filters: observable,
      setFilters: action,
      updateFilterValues: action,
      isSearchByPath: computed,

      isExpandedAll: observable,

      selectedAccount: observable,
      arrangedAccounts: observable,
      onSelectAccount: action,
      isParent: computed,
      hasChildren: computed,
      onToggleExpand: action,
      onToggleExpandNode: action,

      isLoading: observable,
      setIsLoading: action,
    });
  }

  get isParent() {
    return this.selectedAccount?.id === this.organization?.id;
  }

  get hasChildren() {
    return this.selectedAccount?.children?.length > 0;
  }

  getAccountFromId(accounts, selectedId) {
    if (!selectedId) return null;
    for (let i = 0; i < accounts.length; i++) {
      const account = accounts[i];
      if (account.id === selectedId) return account;
      if (account.children.length > 0) {
        const result = this.getAccountFromId(account.children, selectedId);
        if (result) return result;
      }
    }
    return null;
  }

  getAccountFromName(accounts, selectedName) {
    if (!selectedName) return null;
    for (let i = 0; i < accounts.length; i++) {
      const account = accounts[i];
      if (account.name === selectedName) return account;
      if (account.children.length > 0) {
        const result = this.getAccountFromName(account.children, selectedName);
        if (result) return result;
      }
    }
    return null;
  }

  getCurrentAccount() {
    return this.getAccountFromId(this.allAccounts, this.selectedAccount?.id);
  }

  setSearch(value) {
    this.search = value?.trim();
  }

  toggleExpandMore(accounts, selectedId) {
    for (let i = 0; i < accounts.length; i++) {
      const account = accounts[i];
      if (account.id === selectedId) {
        accounts[i] = {
          ...accounts[i],
          isExpanded: account.isExpanded ? false : true,
        };
        return;
      }
      if (account.children.length > 0) {
        const result = this.toggleExpandMore(account.children, selectedId);
        if (result) return;
      }
    }
  }

  onToggleExpandNode(accountId) {
    this.toggleExpandMore(this.allAccounts, accountId);
  }

  onToggleExpand(accountId) {
    this.toggleExpandMore(this.arrangedAccounts, accountId);
  }

  // NOTE: Update Left Nav
  updateExpandedAccounts(accounts, paths) {
    if (paths) {
      for (let i = 0; i < accounts.length; i++) {
        const account = accounts[i];
        if (paths.includes(account.id)) {
          accounts[i] = { ...accounts[i], isExpanded: true };
        } else {
          accounts[i] = { ...accounts[i], isExpanded: false };
        }
        if (account.children.length > 0) {
          this.updateExpandedAccounts(account.children, paths);
        }
      }
    }
  }

  // NOTE: Select account
  onSelectAccount(accountId) {
    if (!this.allAccounts?.length) return;
    this.selectedAccount = this.getAccountFromId(this.allAccounts, accountId);
  }

  setFilters(platformTypes) {
    if (platformTypes)
      this.filters[FilterType.platform].values = platformTypes.map(({ name }) => ({
        label: name,
        value: name,
      }));
  }

  updateFilterValues(key, value) {
    this.filters[key].selectedValues = value;
  }

  get isSearchByPath() {
    return (
      window.location.pathname === BASE_ROUTES.organizations &&
      this.filters[FilterType.searchType].selectedValues?.[0] === SearchTypes.path
    );
  }

  get isCaptchaFilter() {
    return this.filters[FilterType.captcha]?.selectedValues.length > 0;
  }

  get platformFilters() {
    return this.filters[FilterType.platform]?.selectedValues;
  }

  get isEmptyFilter() {
    return (
      !this.search &&
      ((!this.isCaptchaFilter && !this.platformFilters.length) || this.isSearchByPath)
    );
  }

  // NOTE: Filter accounts list
  get filteredAccounts() {
    const { search, allAccounts, arrangedAccounts } = this;
    return search
      ? arrangedAccounts.filter(({ name }) => name?.toLowerCase()?.includes(search.toLowerCase()))
      : allAccounts;
  }

  get filteredOrganizations() {
    const search = this.search?.toLowerCase();
    if (this.isEmptyFilter || this.isSearchByPath) {
      const collapsedAccountIds = this.arrangedAccounts
        ?.filter(
          ({ isExpanded, children }, index) => index > 0 && children.length > 0 && !isExpanded,
        )
        .map(({ id }) => id);
      const expandedResult = collapsedAccountIds
        ? this.arrangedAccounts?.filter(
            ({ path }) => !path.slice(0, -1).some((id) => collapsedAccountIds.includes(id)),
          )
        : this.arrangedAccounts;
      if (!search) return expandedResult;

      return expandedResult?.filter(({ pathByName }) =>
        pathByName?.toLowerCase()?.includes(search),
      );
    }

    return this.arrangedAccounts?.filter(
      ({ id, name, createdOn, profile, orgPlatformLabels }) =>
        (!search ||
          name?.toLowerCase()?.includes(search) ||
          createdOn?.includes(search) ||
          `${id}`.includes(search)) &&
        (!this.isCaptchaFilter || profile.settings?.isCaptcha) &&
        (!this.platformFilters?.length ||
          this.platformFilters.some((platform) => orgPlatformLabels.includes(platform))),
    );
  }

  get childAccounts() {
    return this.allAccounts?.slice(1) ?? [];
  }

  // NOTE: Get Filter Options
  getSubAccounts(accounts) {
    return accounts.reduce((acc, account) => {
      let subAccounts = [];
      if (account.children.length > 0) {
        subAccounts = this.getSubAccounts(account.children);
      }
      return acc.concat([{ value: { id: account.id, name: account.name } }]).concat(subAccounts);
    }, []);
  }

  getExpandAllAccounts(accounts, isExpanded = true) {
    return accounts.map((account) => {
      return {
        ...account,
        isExpanded,
        children:
          account.children.length > 0
            ? this.getExpandAllAccounts(account.children, isExpanded)
            : [],
      };
    });
  }

  expandAllOrgs(accounts, isExpanded = true) {
    accounts.forEach((account) => {
      account.isExpanded = isExpanded;
      if (account.children.length > 0) this.expandAllOrgs(account.children, isExpanded);
    });
  }

  expandAllNodes() {
    this.isExpandedAll = !this.isExpandedAll;
    this.expandAllOrgs(this.arrangedAccounts, this.isExpandedAll);
  }

  // NOTE: Fetch organizations list list
  async fetchAccounts() {
    const organizationId = userStore.organizationId;
    let result = null;
    if (!organizationId) return null;

    this.setIsLoading(true);

    try {
      result = await organizationApi.getOrganizationFullTree(organizationId);
      const { parent: organization, children: accounts, arrangedAccounts } = result;

      runInAction(() => {
        this.allAccounts = [organization].concat(accounts);
        this.arrangedAccounts = this.getExpandAllAccounts(arrangedAccounts, this.isSearchByPath);
        this.isExpandedAll = this.isSearchByPath;
        userStore.setAppLoaded();
      });
      
    } catch (err) {
      setError(err, true);
    }

    this.setIsLoading(false);
    return result;
  }

  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  dispose() {
    // TBD
  }
}

export const accountStore = new AccountStore();
