import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { orderBy } from 'lodash';

import { convertLocalDateTime, getAssignFromName, getTicketLink } from '../utils';
import { ticketApi } from '../api';
import { setError } from '../utils/errors';
import { ColumnType } from '@/utils/types';
import { accountStore } from './AccountStore';

const HEADER_COLUMNS = [
  {
    key: ColumnType.ticketId,
    label: 'TicketId',
    sortKey: ColumnType.ticketId,
    align: 'left',
    width: 72,
  },
  {
    key: ColumnType.accountId,
    label: 'Organization',
    align: 'left',
    sortKey: ColumnType.accountName,
    width: 120,
  },
  {
    key: ColumnType.label,
    label: 'Label',
    align: 'left',
    width: 70,
    sortKey: ColumnType.label,
  },
  {
    key: ColumnType.intentType,
    label: 'Service',
    sortKey: 'workflow',
    align: 'left',
    width: 120,
  },
  {
    key: ColumnType.lastRun,
    label: 'Last Run',
    align: 'left',
    width: 80,
    sortKey: ColumnType.lastRun,
  },
  {
    key: ColumnType.createdDate,
    label: 'Created Date',
    sortKey: ColumnType.createdOn,
    align: 'left',
    width: 80,
  },
];

export class ScheduleStore {
  isLoading = false;
  scheduleList = [];
  organizationId = null;
  sortKey = 'id';
  sortDirection = 'asc';
  selectedSchedule = null;
  search = '';

  constructor() {
    makeObservable(this, {
      organizationId: observable,
      setOrganizationId: action,

      scheduleList: observable,
      filteredScheduleList: computed,
      fetchScheduleList: action,

      sortKey: observable,
      sortDirection: observable,
      toggleSort: action,
      headerColumns: computed,

      selectedSchedule: observable,
      setSelectedSchedule: action,
      selectedScheduleId: computed,

      search: observable,
      setSearch: action,

      isLoading: observable,
      setIsLoading: action,
    });
  }

  getSortDirection(sortKey) {
    if (this.sortKey === sortKey) return this.sortDirection;
    return 'asc';
  }

  isActiveSort(sortKey) {
    return this.sortKey === sortKey;
  }

  // NOTE: Sort table rows by order
  toggleSort(sortKey) {
    let direction = 'asc';
    if (this.sortKey === sortKey) {
      direction = this.sortDirection === 'asc' ? 'desc' : 'asc';
    }
    this.sortKey = sortKey;
    this.sortDirection = direction;
  }

  setOrganizationId(value) {
    this.organizationId = value;
  }

  setIsLoading(value) {
    this.isLoading = value;
  }

  setSelectedSchedule(value) {
    this.selectedSchedule = value;
  }

  get selectedScheduleId() {
    return this.selectedSchedule?.id;
  }

  get headerColumns() {
    return HEADER_COLUMNS;
  }

  getScheduleInfo(schedule) {
    const owner = getAssignFromName(schedule.ownedByName);
    const account = accountStore.getAccountFromId(
      accountStore.allAccounts,
      schedule.organizationId,
    );
    return {
      ...schedule,
      ticketUrl: getTicketLink(schedule.ticketId),
      [ColumnType.ownedByName]: owner.label,
      [ColumnType.ownerType]: owner.type,
      [ColumnType.accountName]: account?.name,
      [ColumnType.lastRun]: convertLocalDateTime(schedule.lastRun),
    };
  }

  setSearch(value) {
    this.search = value?.trim();
  }

  get filteredScheduleList() {
    const search = this.search.toLowerCase();

    const filteredSchedules = !this.search
      ? this.scheduleList
      : this.scheduleList.filter((s) => {
          return (
            `${s.ticketId}`.toLowerCase().includes(search) ||
            s.label.toLowerCase().includes(search) ||
            s.state.toLowerCase().includes(search) ||
            s.accountName.toLowerCase().includes(search) ||
            s.workflow.toLowerCase().includes(search) ||
            s.title.toLowerCase().includes(search)
          );
        });

    return orderBy(
      filteredSchedules,
      [(schedule) => schedule[this.sortKey]?.toString().toLowerCase()],
      [this.sortDirection],
    );
  }

  async saveSchedule(schedule) {
    try {
      const result = await ticketApi.saveSchedule(schedule);
      runInAction(() => {
        const newSchedule = this.getScheduleInfo(result);
        if (schedule.id) {
          const idx = this.scheduleList.findIndex((s) => s.id === schedule.id);
          if (idx > -1) {
            this.scheduleList[idx] = newSchedule;
          }
        } else {
          this.scheduleList.push(newSchedule);
        }
        this.setSelectedSchedule(newSchedule);
      });
    } catch (err) {
      setError(err, true, `Failed to ${schedule.id ? 'save' : 'create'} schedule`);
    }
  }

  async deleteSchedule() {
    try {
      await ticketApi.deleteSchedule(this.selectedScheduleId);
      runInAction(() => {
        this.scheduleList = this.scheduleList.filter((s) => s.id !== this.selectedScheduleId);
        this.setSelectedSchedule();
      });
    } catch (err) {
      setError(err, true, `Failed to delete schedule`);
    }
  }

  async fetchScheduleList() {
    this.setIsLoading(true);
    try {
      const scheduleList = await ticketApi.getScheduleList(this.organizationId);
      runInAction(() => {
        this.scheduleList = (scheduleList ?? []).map(this.getScheduleInfo);
        if (this.scheduleList.length && (!this.selectedSchedule || this.selectedScheduleId))
          this.setSelectedSchedule(this.scheduleList[0]);
      });
    } catch (err) {
      setError(err);
    }
    this.setIsLoading(false);
  }

  dispose() {}
}
