import { useCallback, useState } from 'react';

export default function AsyncComponent({
  component: Component,
  disabled,
  onClick,
  children,
  ...otherProps
}) {
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = useCallback(async () => {
    if (disabled || isDisabled) return;
    setIsDisabled(true);
    await onClick();
    setIsDisabled(false);
  }, [isDisabled, disabled, onClick]);

  if (children)
    return (
      <Component {...otherProps} disabled={disabled || isDisabled} onClick={handleClick}>
        {children}
      </Component>
    );

  return <Component {...otherProps} disabled={disabled || isDisabled} onClick={handleClick} />;
}
