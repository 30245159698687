import {
  SettingsOutlined,
  Close,
  EditOutlined,
  MessageOutlined,
  DateRangeOutlined,
  Circle,
  ArrowLeft,
  ArrowRight,
  ArrowDropUp,
  ArrowDropDown,
  SaveOutlined,
  DeleteOutlined,
  HighlightOffOutlined,
  BlockOutlined,
  CheckCircleOutlineOutlined,
  DownloadOutlined,
  ErrorOutlineOutlined,
  PendingOutlined,
  ChangeCircleOutlined,
} from '@mui/icons-material';

import { theme } from '../config';

export const BASE_ROUTES = {
  main: '/',
  detail: '/tickets',
  workflows: '/workflows',
  organizations: '/organizations',
  schedules: '/schedules',
  users: '/users',
  bulk: '/bulk',
  reports: '/reports',
  settings: '/settings',
  env: '/env',
};

export const ServiceTypes = {
  single: 'single',
  multiple: 'multiple',
};

export const TRISTATE = {
  unchecked: 0,
  checked: 1,
  indeterminate: 2,
};

export const PAGE_FILTERS = {
  [BASE_ROUTES.main]: 'tickets', //  Tickets page
  [BASE_ROUTES.organizations]: '', //  Organizations page
  [BASE_ROUTES.users]: 'users', //  Users page
};

export const UrlSearch = {
  organization: 'organization',
  menu: 'menu',
  workflowType: 'wconf',
  request: 'request',
  ticket: 'ticketId',
  activeTicket: 'activeId',
  page: 'page',
  pageRows: 'pagerows',
  sort: 'sort',
  search: 'search',
  emailNotifications: 'receiveNotifications',
  createdAfter: 'createdAfter',
  createdTicketId: 'createdTicketId',
  tab: 'tab',
  hideBanner: 'ifr',
  token: 'token',
  viewBy: 'columns',
};

export const AssignType = {
  unassigned: 'Unassigned',
  user: 'user',
  helpdesk: 'helpdesk',
  workflow: 'workflow',
  amazon: 'amazon',
  lightBlue: 'light-blue',
  lightPurple: 'light-purple',
  darkBlue: 'dark-blue',
  purple: 'purple',
  teal: 'teal',
  organization: 'organization',
};

export const Unassigned = {
  id: null,
  value: null,
  name: AssignType.unassigned,
  label: AssignType.unassigned,
  email: '',
  type: AssignType.unassigned,
};

export const AmazonStatus = {
  attention: 'Needs Your Attention',
  reOpened: 'Re-Opened',
  pending: 'Pending Amazon Action',
  unassigned: 'Unknown',
  answered: 'Answered',
  closed: 'Closed',
};

export const HelpDeskStatus = {
  badge: 'Badge',
  notStarted: 'Not Started',
  open: 'Open',
  inProgress: 'In Progress',
  attention: 'Needs Attention',
  blocked: 'Blocked',
  failed: 'Failed',
  resolved: 'Resolved',
  canceled: 'Canceled',
  closed: 'Closed',
  todo: 'To Do',
  doing: 'Doing',
  needs: 'Needs Approval',
};

export const ItemTypes = {
  COLUMN: 'COLUMN',
};

export const AnchorOrigin = {
  auto: 'auto',
  bottom: 'bottom',
  top: 'top',
};

export const ColumnType = {
  select: 'selected',
  id: 'id',
  workActivityId: 'workActivityId',
  status: 'status',
  state: 'state',
  accountId: 'accountId',
  accountName: 'accountName',
  taskType: 'taskType',
  title: 'title',
  createdDate: 'createdDate',
  createdByType: 'createdByType',
  createdByName: 'createdByName',
  ownedBy: 'ownedBy',
  ownedByName: 'ownedByName',
  ownerType: 'ownerType',
  organization: 'organization',
  assignedTo: 'assignedTo',
  assignedName: 'assignedToName',
  assignType: 'assignType',
  lastUpdated: 'lastUpdated',
  lastComment: 'lastComment',
  isPinned: 'isPinned',
  isRead: 'isRead',
  externalId: 'externalId',
  externalStatus: 'externalStatus',
  priority: 'priority',
  estimatedDate: 'ecd',
  intentType: 'intentType',
  objectType: 'objectType',
  remainingWork: 'remainingWork',
  requestedByDate: 'requestedByDate',
  attachments: 'attachments',
  addedAttachments: 'deletedAttachments',
  deletedAttachments: 'deletedAttachments',
  //Workflows Table
  config: 'config',
  buildId: 'buildId',
  queued: 'queued',
  automationTime: 'automationTime',
  buildNumber: 'buildNumber',
  buildError: 'error',
  automation: 'automation',
  action: 'action',
  // Reporting Log Table
  fromage: 'fromage',
  service: 'service',
  totalRequests: 'totalRequests',
  quantity: 'quantity',
  pastDue: 'pastDue',
  resolvedRequests: 'resolvedRequests',
  sumOfCount: 'sumOfCount',
  daysToResolve: 'daysToResolve',
  pastDueResolve: 'pastDueResolve',
  openRequests: 'openRequests',
  pastDueOpen: 'pastDueOpen',
  pastDueOpenCount: 'pastDueOpenCount',
  avgResolution: 'avgResolution',
  standardDeviation: 'standardDeviation',
  cases: 'cases',
  casePerRequest: 'casePerRequest',
  updatedOn: 'updatedOn',
  // Schedule Table
  ticketId: 'ticketId',
  cron: 'cron',
  label: 'label',
  lastRun: 'lastRun',
  createdOn: 'createdOn',
  paused: 'paused',
  // Bulk
  path: 'path',
  organizationPath: 'organizationPath',
  workflow: 'workflow',
  resolved: 'resolved',
  total: 'total',
  assignedToName: 'assignedToName',
  commentedByName: 'commentedByName',
  // Triggers Table
  triggeredOn: 'triggeredOn',
};

export const ObjectType = {
  none: 'none',
  request: 'Request',
  case: 'Case',
  task: 'Task',
  workflow: 'Workflow',
  question: 'Question',
  issue: 'Issue',
  phase: 'Phase',
};

export const AutomationStatusColors = {
  Queued: '#1379D2',
  Running: '#1379D2',
  Succeeded: '#56A364',
  Failed: theme.palette.ui.error,
  Canceled: theme.palette.neutrals.greyMed,
  Manual: theme.palette.secondary.salmon,
  none: theme.palette.neutrals.lightGrey,
};

export const EmojiIcons = {
  pen: <>✏️</>,
  hourglass: <>⏳</>,
  save: <>💾</>,
  delete: <>🗑️</>,
  close: <>🗙</>,
  refresh: <>⟳</>,
  succeeded: <>☑</>,
  failed: <>⊗</>,
  canceled: <>⊘</>,
  download: <>⬇️</>,
  up: <>⏶</>,
  down: <>⏷</>,
  left: <>⏴</>,
  right: <>⏵</>,
  manual: <>⚠</>,
  dot: <>●</>,
  schedules: <>📅</>,
  settings: <>⚙️</>,
  message: <>💬</>,
};

export const MuiIcons = {
  pen: <EditOutlined />,
  hourglass: <PendingOutlined />,
  save: <SaveOutlined />,
  delete: <DeleteOutlined />,
  close: <Close />,
  refresh: <ChangeCircleOutlined />,
  succeeded: <CheckCircleOutlineOutlined />,
  failed: <HighlightOffOutlined />,
  canceled: <BlockOutlined />,
  download: <DownloadOutlined />,
  up: <ArrowDropUp />,
  down: <ArrowDropDown />,
  left: <ArrowLeft />,
  right: <ArrowRight />,
  manual: <ErrorOutlineOutlined />,
  dot: <Circle />,
  schedules: <DateRangeOutlined />,
  settings: <SettingsOutlined />,
  message: <MessageOutlined />,
};

export const AutomationStatusIcons = {
  Queued: MuiIcons.hourglass,
  Running: MuiIcons.refresh,
  Succeeded: MuiIcons.succeeded,
  Failed: MuiIcons.failed,
  Canceled: MuiIcons.canceled,
  Manual: MuiIcons.manual,
  none: MuiIcons.dot,
};

export const RequestTypes = {
  child: 'false',
  request: 'true',
  all: 'all',
};

export const PriorityOptions = [
  {
    value: 2,
    label: 'Normal',
  },
  {
    value: 1,
    label: 'High',
  },
  {
    value: 0,
    label: 'Urgent',
  },
];

export const NotificationText = {
  createRequestSuccess: 'Request successfully created.',
  createTicketSuccess: 'Ticket successfully created.',
  createCaseSuccess: 'Amazon Case successfully created.',
  createQuestionSuccess: 'Question successfully created.',
  saveTicketSuccess: 'Ticket successfully saved.',
  createdError: 'Create Request failed',
  invalidFile: 'Invalid file!',
  cancelRequestError: 'Cancel Request failed.',
  applyPinError: 'Apply Pin failed',
  detailPageFetchError: 'Detail page fetch failed.',
  fetchQuestionError: 'Question fetch failed.',
  cancelQuestionError: 'Cancel Question failed.',
  updateQuestionError: 'Question update failed.',
  updateTaskItemError: 'Ticket update failed.',
  updateStatusError: 'Status update failed.',
  updateAssignError: 'Assign update failed.',
  createCaseError: 'Create Case failed.',
  addCommentError: 'Add comment failed.',
  deleteCommentError: 'Delete comment failed.',
  updateCommentError: 'Update comment failed.',
  fetchCommentsError: 'Comments fetch failed',
  addAnswerError: 'Add answer failed.',
  deleteAnswerError: 'Delete answer failed',
  updateAnswerError: 'Update answer failed',
  createQuestionError: 'Create Question failed',
  deleteRequestError: 'Delete request failed',
  deleteTicketError: 'Delete task failed',
  updateProfileNameError: 'Update profile name failed',
  toggleEmailsNotification: 'Toggle Emails Notification failed',
  createUserError: 'Create user failed',
  updateUserError: 'Update user failed',
  removeUserError: 'Remove user failed',
  updateProfileLastLogError: 'Update profile last url failed',
  getWorkflowInfoError: 'Get workflow config failed',
  notReachable: 'The server is not reachable.',
};

export const FeedbackType = {
  satisfied: ':smile:',
  dissatisfied: ':disappointed:',
};
