import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import Dropzone from 'react-dropzone';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import PublishIcon from '@mui/icons-material/Publish';
import { Button, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import NoBulletIcon from '@/assets/icons/NoBullet.svg';

const useStyles = makeStyles((theme) => ({
  imageUploader: {
    position: 'relative',
    fontSize: '14px',
    width: '338px',
    height: '338px',
    background: 'transparent',
  },
  dropzone: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    border: 'none',
    color: theme.palette.neutrals.greyMed,
    backgroundImage:
      "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D0D0D0' stroke-width='1' stroke-dasharray='12%2c 12' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e\")",
    cursor: 'pointer',
  },
  activeDropzone: {
    background: 'transparent',
  },
  dropZoneWithImage: {
    background: '#fff',
    border: '1px solid #E8E8E8',
  },
  uploadIcon: {
    fontSize: '48px !important',
    marginBottom: '10px',
  },
  selectFileWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  selectFile: {
    color: `${theme.palette.primary.purple} !important`,
    background: 'none !important',
    boxShadow: 'none !important',
    textTransform: 'none !important',
    padding: '0 4px',
    height: '20px',
    fontWeight: '700 !important',
    fontSize: '16px !important',
    letterSpacing: '0.15px',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  fileInput: {
    display: 'none !important',
    userSelect: 'none !important',
  },
  dropLabel: {
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.secondary.darkBlue,
  },
  imgPreview: {
    width: '100%',
    height: '100%',
    background: 'transparent',
    objectFit: 'contain',
  },
  secondary: {
    width: '160px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  smallImageView: {
    width: '40px',
    height: '40px',
  },
  noImageIcon: {
    width: '16px',
    height: '16px',
    objectFit: 'cover',
  },
  number: {
    fontWeight: 400,
    fontSize: '10.5px',
    lineHeight: '166%',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.6)',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  buttonGroup: {
    display: 'flex',
    paddingRight: '4px',
  },
  button: {
    width: '32px !important',
    height: '32px !important',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  buttonIcon: {
    fontSize: '16px !important',
  },
  errorBorder: {
    border: '1px solid #f34e4e !important',
  },
  error: {
    marginTop: '8px',
    color: '#F34E4E',
  },
}));

function ImageUploader({
  variant,
  file,
  name,
  number,
  contentClassName,
  dropZoneClassName,
  previewClassName,
  handleDropFile,
  handleFileInputChange,
  handleDeleteFile,
  disabled,
  isClearMode,
  clearIcon,
  hideIcon,
  error,
}) {
  const styles = useStyles();
  const imgRef = useRef();

  useEffect(() => {
    if (file && !file.preview) {
      const reader = new FileReader();
      reader.addEventListener('load', (event) => {
        imgRef.current.src = event.target.result;
      });
      reader.readAsDataURL(file);
    }
  }, [file, variant]);

  if (variant === 'primary') {
    return (
      <div className={cx(styles.imageUploader, contentClassName)} id="ImageUploader">
        <Dropzone
          onDrop={handleDropFile}
          className={cx(
            styles.dropzone,
            { [styles.errorBorder]: !!error, [styles.dropZoneWithImage]: file },
            dropZoneClassName,
          )}
          activeClassName={styles.activeDropzone}
          accept="image/*"
          disabled={disabled || (isClearMode && Boolean(file))}
        >
          {!file ? (
            <>
              {!hideIcon && <CloudUploadIcon className={styles.uploadIcon} />}
              <div className={styles.selectFileWrapper}>
                <Button component="label" key="import" className={styles.selectFile}>
                  Select {name}
                </Button>
                <div className={styles.dropLabel}>or drag and drop here</div>
              </div>
            </>
          ) : (
            <Tooltip
              title={disabled ? 'Organization Logo Icon' : 'Click or drag and drop here'}
              placement="top"
            >
              <div style={{ position: 'relative' }} className={previewClassName}>
                <img
                  className={cx(styles.imgPreview)}
                  src={file.preview}
                  alt="No main"
                  ref={imgRef}
                />
                {clearIcon}
              </div>
            </Tooltip>
          )}

          <input
            className={styles.fileInput}
            type="file"
            accept="image/*"
            onChange={handleFileInputChange}
            disabled={disabled}
          />
        </Dropzone>
        {error && <div className={styles.error}>{error}</div>}
      </div>
    );
  }

  return (
    <div className={cx(styles.imageUploader, styles.secondary)}>
      <div className={styles.imageContainer}>
        <span className={styles.number}>{number}</span>
        <div className={styles.smallImageView}>
          {!file ? (
            <Dropzone
              onDrop={handleDropFile}
              className={styles.dropzone}
              activeClassName={styles.activeDropzone}
              accept="image/*"
              disabled={disabled}
            >
              <img src={NoBulletIcon} className={styles.noImageIcon} alt="No bullet" />
            </Dropzone>
          ) : (
            <img className={styles.imgPreview} src={file.preview} alt="No main" ref={imgRef} />
          )}
        </div>
      </div>
      <div className={styles.buttonGroup}>
        <IconButton classes={{ root: styles.button }} component="label">
          <PublishIcon className={styles.buttonIcon} />
          <input id="secondary" type="file" accept="image/*" onChange={handleFileInputChange} />
        </IconButton>
        <IconButton
          classes={{ root: styles.button }}
          style={{ visibility: !file ? 'hidden' : 'visible' }}
          onClick={handleDeleteFile}
        >
          <DeleteIcon className={styles.buttonIcon} />
        </IconButton>
      </div>
    </div>
  );
}

ImageUploader.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary']),
  file: PropTypes.any,
  number: PropTypes.number,
  handleDropFile: PropTypes.func.isRequired,
  handleFileInputChange: PropTypes.func.isRequired,
  handleDeleteFile: PropTypes.func,
  disabled: PropTypes.bool,
  isClearMode: PropTypes.bool,
  clearIcon: PropTypes.node,
  hideIcon: PropTypes.bool,
  name: PropTypes.string,
};

ImageUploader.defaultProps = {
  variant: 'primary',
  file: null,
  number: 0,
  handleDeleteFile: undefined,
  disabled: false,
  isClearMode: false,
  clearIcon: null,
  hideIcon: false,
  name: 'File',
};

export default ImageUploader;
