import { observer } from 'mobx-react-lite';
import cx from 'clsx';

import TreeSubItem from '../OrgAccessDropdown/TreeSubItem';
import Accordion from '../Accordion';
import AccordionSummary from '../AccordionSummary';
import AccordionDetails from '../AccordionDetails';

import styles from './TreeListDropdown.module.css';

const PAGE_NAME = 'TreeListPanel';

export const TreeListPanel = observer(
  ({ treeList, selectedValues, multiSelect, selectCategory, onChange, disabled }) => {
    const onToggleExpandCategory = (nodeName) => {
      function toggleExpandMore(nodeList, selectedNode) {
        for (let i = 0; i < nodeList.length; i++) {
          const node = nodeList[i];
          if (node.name === selectedNode) {
            nodeList[i] = {
              ...nodeList[i],
              isExpanded: node.isExpanded ? false : true,
            };
            return true;
          }
          if (node.children.length > 0) {
            const result = toggleExpandMore(node.children, selectedNode);
            if (result) return result;
          }
        }
      }
      toggleExpandMore(treeList, nodeName);
    };

    const nodeCategoryItem = (node, layer) => {
      return (
        <Accordion
          key={node.name}
          data-testid={`${PAGE_NAME}_accordion_${node.name}`}
          classes={{
            accordionRoot: styles.accordionRoot,
            accordionExpanded: styles.accordionExpanded,
          }}
          sx={{
            '&:before': {
              display: layer !== 0 && 'none',
            },
          }}
          id={`${PAGE_NAME}_nodes_accordion`}
          expanded={!!node.isExpanded}
        >
          <AccordionSummary
            id={`${PAGE_NAME}_nodes_accordion_content`}
            containerClassName={styles.accordionSummary}
            contentClassName={styles.accordionSummaryContent}
            data-testid={`${PAGE_NAME}_accordionSummary_${node.name}`}
            >
            <TreeSubItem
              disabled={disabled}
              isCheckbox={multiSelect}
              selectCategory={selectCategory}
              checked={selectedValues.includes(node.id)}
              key={node.id}
              isChild={node.children?.length > 0}
              isExpanded={!!node.isExpanded}
              isSelected={selectedValues === node.id}
              label={node.displayName || node.name}
              match={node.match}
              onSelect={(e) => {
                if (!disabled && (selectCategory || !node.children?.length)) {
                  e.stopPropagation();
                  const value = node.id;
                  const checked = selectedValues.includes(value);
                  if (multiSelect) {
                    onChange(
                      checked
                        ? selectedValues.filter((o) => o !== value)
                        : selectedValues.concat(value),
                    );
                  } else {
                    onChange([value]);
                  }
                }
              }}
              onToggleExpand={(e) => {
                e.stopPropagation();
                onToggleExpandCategory(node.name);
              }}
            />
          </AccordionSummary>
          {node.children?.length > 0 && (
            <AccordionDetails className={cx(styles.accordionDetails)}>
              {node.children.map((category) => nodeCategoryItem(category, layer + 1))}
            </AccordionDetails>
          )}
        </Accordion>
      );
    };

    return (
      <div className={cx(styles.popoverContent, styles.panel, { [styles.active]: !disabled })}>
        <div className={styles.sideContainer}>
          {treeList.map((config) => nodeCategoryItem(config, 0))}
        </div>
      </div>
    );
  },
);
