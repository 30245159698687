import { useMemo } from 'react';

import { workflowApi } from '@/api';
import { Typography, UploadAttachment, AsyncComponent } from '@/components';
import { ACCEPT_TEMPLATE_FILES } from '@/utils/constants';
import { WARNING_COLOR } from '@/utils/constants';
import { setError } from '@/utils/errors';
import { mapConfigFile } from '@/utils/staticForm';

import styles from './TemplateAttachment.module.css';

const PAGE_NAME = 'TemplateAttachment';

function TemplateAttachment({
  workflowType,
  templateFile,
  onUpdateFile,
  label,
  showWarning,
  noTemplate,
  disabled,
}) {
  const showTemplateFile = Boolean(templateFile);
  const isWarning = showWarning && !showTemplateFile;

  const uploadedFiles = useMemo(
    () => (templateFile ? [mapConfigFile(templateFile)] : []),
    [templateFile],
  );

  const handleDownloadTemplateFile = async () => {
    try {
      const downloadLink = await workflowApi.getProductsTemplateFile();
      if (downloadLink) {
        const a = document.createElement('a');
        a.href = downloadLink;
        a.download = `${workflowType}-${label}.csv`;
        a.click();
      }
    } catch (err) {
      setError(err, false, 'Workflow template file download failed');
    }
  };

  return (
    <div id={`${PAGE_NAME}_uploadTemplate_container`}>
      <div className={styles.row}>
        <Typography
          variant="subtitle2"
          sx={{ color: isWarning ? WARNING_COLOR : '' }}
          tooltipText={`Upload a CSV file with the ${workflowType} ${label} information.`}
        >
          {label}
        </Typography>
        {!noTemplate && (
          <AsyncComponent
            component={Typography}
            variant="body2"
            className={styles.linkText}
            onClick={handleDownloadTemplateFile}
          >
            Download Template
          </AsyncComponent>
        )}
      </div>
      <UploadAttachment
        contentClassName={styles.uploader}
        onUpdateFile={(files) => onUpdateFile(files[0])}
        files={uploadedFiles}
        accept={ACCEPT_TEMPLATE_FILES}
        multiple={false}
        warning={isWarning}
        disabled={disabled}
        hideDropZone={disabled || showTemplateFile}
      />
    </div>
  );
}

export default TemplateAttachment;
