import axios from 'axios';
import { Config } from '../config';
import { userStore } from '../store';
import * as fetchWithAuth from './fetchWithAuth';
import { parseUser, parseUserFromObj } from './parseUtils';
import { FetchOptions } from './constants';

export const getMyProfile = async () => {
  return fetchWithAuth.get(`${Config.NEW_API}/Users/myprofile`).then((resp) => parseUser(resp));
};

export const saveUser = async (data) => {
  return fetchWithAuth
    .post(`${Config.NEW_API}/Users/save`, parseUserFromObj(data), FetchOptions.status)
    .then((resp) => resp);
};

const getUserData = () => {
  const me = userStore.currentUser;
  const data = {
    id: me.id,
    organizationId: me.organizationId,
    organizationPath: me.organizationPath,
    authId: me.authId,
    email: me.email,
    first: me.first,
    last: me.last,
    name: me.name,
    image: me.image,
    isActive: me.isActive,
    isAdmin: me.isAdmin,
    isAssignable: me.isAssignable,
    isContributor: me.isContributor,
    createdOn: me.createdOn,
    profile: {
      id: me.id,
      filters: me.filters,
      emailsEnabled: me.emailsEnabled,
      pinnedTickets: me.pinnedTickets,
      pinnedRequests: me.pinnedRequests,
      ticketColumns: me.ticketColumns,
    },
  };
  return data;
};

export const updateMyProfile = async () => {
  const data = getUserData();
  return fetchWithAuth
    .put(`${Config.NEW_API}/Users/myprofile`, data, FetchOptions.status)
    .then((resp) => resp);
};

export const toggleEmailsNotification = async (emailsEnabled) => {
  const user = getUserData();
  const data = { ...user, profile: { ...user.profile, emailsEnabled } };
  return fetchWithAuth
    .put(`${Config.NEW_API}/Users/myprofile`, data, FetchOptions.status)
    .then((resp) => resp);
};

export const updateUserFilters = async (pageParam) => {
  const user = getUserData();
  const data = {
    ...user,
    profile: { ...user.profile, filters: { ...user.profile?.filters, ...pageParam } },
  };
  return fetchWithAuth
    .put(`${Config.NEW_API}/Users/myprofile`, data, FetchOptions.status)
    .then((resp) => resp);
};

export const removeUser = async (userId, email) => {
  return fetchWithAuth
    .put(`${Config.NEW_API}/Users/${userId}/disable?email=${email}`, userId, FetchOptions.status)
    .then((resp) => resp);
};

export const reassignTasks = async (fromUserId, toUserId) => {
  return fetchWithAuth
    .put(`${Config.NEW_API}/Users/reassign?userId=${fromUserId}&toUserId=${toUserId}`, {})
    .then((resp) => resp);
};

export const reinviteUser = async (userId) => {
  return fetchWithAuth.put(`${Config.NEW_API}/Users/${userId}/reinvite`).then((resp) => resp);
};

export const getAPIAccess = async (userId) => {
  return fetchWithAuth.get(`${Config.NEW_API}/Users/access/${userId}`).then((resp) => resp);
};

export const toggleAPIAccess = async (userId, enabled) => {
  return fetchWithAuth
    .post(`${Config.NEW_API}/Users/access/${userId}/toggle?enabled=${enabled}`)
    .then((resp) => resp);
};

export const refreshAPIAccess = async (userId) => {
  return fetchWithAuth.put(`${Config.NEW_API}/Users/access/${userId}/refresh`).then((resp) => resp);
};

export const isValidateToken = async (token) => {
  if (!token) return false;
  return fetchWithAuth
    .get(`${Config.NEW_API}/Auth/validate_token?token=${token}`, FetchOptions.noAuth)
    .then((resp) => resp);
};

export const sendMessageToSlack = async (username, channelURL, message, sendIcon) => {
  const data = {
    username,
    text: message,
    icon_emoji: sendIcon,
  };
  if (process.env.REACT_APP_DEBUG_TEST_ENVIRONMENT) {
    console.log('Here is a Slack Message:', message);
  } else {
    await axios.post(channelURL, JSON.stringify(data));
  }
};

export const sendErrorMessageToSlack = async (message) => {
  if (Config.APP_VERSION === 'Prod') {
    sendMessageToSlack(
      'Prod-FE-Bug-Reporter',
      'https://hooks.slack.com/services/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',
      `*${Config.APP_VERSION}* ${message}\n<!channel>`,
      ':red_circle:',
    );
  } else {
    sendMessageToSlack(
      'Dev-FE-Bug-Reporter',
      'https://hooks.slack.com/services/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',
      `*${Config.APP_VERSION}* ${message}`,
      ':warning:',
    );
  }
};

export const sendFeedback = async (ticketId, rateIcon, additionalFeedback) => {
  const message = `Feedback By *${userStore.currentUser?.name}* for <${
    window.location.href
  }|*Ticket ${ticketId}*>: ${rateIcon}\r\n${additionalFeedback ?? ''}`;
  sendMessageToSlack(
    'Feedback-Reporter',
    'https://hooks.slack.com/services/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',
    message,
    rateIcon,
  );
};
