import { DEFAULT_CRONS } from './constants';

function parseCron(cronString) {
  // Split the cron string by spaces
  const parts = cronString.trim().split(/\s+/);
  if (parts.length !== 5) {
    return null;
  }
  const [minute, hour, dayOfMonth, month, dayOfWeek] = parts;
  return {
    minute: minute,
    hour: hour,
    dayOfMonth: dayOfMonth,
    month: month,
    dayOfWeek: dayOfWeek,
  };
}

export function getCronLabel(value) {
  const dateObj = parseCron(value);
  if (!dateObj) return '';
  const option = DEFAULT_CRONS.find((item) => item.isCronValue && item.value === value);
  if (option) return option.label;
  if (dateObj.dayOfMonth === '*' && dateObj.month === '*') {
    switch (dateObj.dayOfWeek) {
      case '0':
        return 'Every Week on Sunday';
      case '1':
        return 'Every Week on Monday';
      case '2':
        return 'Every Week on Tuesday';
      case '3':
        return 'Every Week on Wednesday';
      case '4':
        return 'Every Week on Thursday';
      case '5':
        return 'Every Week on Friday';
      case '6':
        return 'Every Week on Saturday';
      default:
        return '';
    }
  }
  if (dateObj.dayOfWeek === '*') {
    let monthStr;
    let dateSuffix;

    // check month
    const monthStrLength = dateObj.month?.length;
    switch (monthStrLength) {
      case 1:
      case 2:
        monthStr = '12 Months';
        break;
      case 3:
      case 4:
        monthStr = '6 Months';
        break;
      case 8:
        monthStr = '3 Months';
        break;
      default:
        break;
    }
    if (dateObj.month === '*') monthStr = 'Month';
    if (!monthStr) return '';

    // check day of month
    const dayOfMonth = +dateObj.dayOfMonth;
    switch (dateObj.dayOfMonth) {
      case 1:
      case 21:
      case 31:
        dateSuffix = 'st';
        break;
      case 2:
      case 22:
        dateSuffix = 'nd';
        break;
      case 3:
      case 23:
        dateSuffix = 'rd';
        break;
      default:
        dateSuffix = 'th';
        break;
    }
    if (dayOfMonth < 1 || dayOfMonth > 31) return '';

    return `Every ${monthStr} on ${dateObj.dayOfMonth}${dateSuffix} Day`;
  }
  return '';
}
