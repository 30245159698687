import { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { cloneDeep, isEqual } from 'lodash';

import DetailContent from './DetailContent';
import { useSettingsStore, userStore } from '@/store';
import { LoadingIndicator } from '@/components';
import { PAGE_MODE } from '@/utils/constants';
import { BASE_ROUTES, UrlSearch } from '@/utils/types';

import styles from './OrganizationPage.module.css';

const OrganizationPage = () => {
  const { organizationStore, accountStore, routerStore, membersStore } = useSettingsStore();
  const [pageMode, setPageMode] = useState(PAGE_MODE.none);
  const initLoadingRef = useRef(true);
  const prevParams = useRef();
  const urlParams = routerStore.getSearchChunkParams();
  const location = useLocation();

  useEffect(() => {
    organizationStore.fetchEventTypes();
    setPageMode(PAGE_MODE.loading);
    routerStore.setCurrentPage(BASE_ROUTES.organizations);
  }, []);

  useEffect(() => {
    const fetchAccounts = async () => {
      const result = await accountStore.fetchAccounts();
      if (result) membersStore.fetchAllAccounts(cloneDeep(result));
    };
    if (userStore.organizationId) userStore.fetchLookupsInfo(userStore.organizationId);

    fetchAccounts();
  }, [userStore.organizationId, userStore.isRefresh]);

  useEffect(() => {
    if (routerStore.currentPage !== BASE_ROUTES.organizations) {
      routerStore.setCurrentPage(BASE_ROUTES.organizations);
      return;
    }
    const orgId = Number(urlParams[UrlSearch.organization]);
    if (prevParams.current && isEqual(prevParams.current, urlParams)) return;
    if (accountStore.allAccounts.length === 0) return;
    prevParams.current = urlParams;

    // NOTE: Update account
    if (orgId) {
      accountStore.onSelectAccount(orgId);
    }

    const searchTxt = urlParams[UrlSearch.search];
    accountStore.setSearch(searchTxt || '');
    initLoadingRef.current = false;
  }, [accountStore.allAccounts, urlParams]);


  const handleClose = useCallback(() => {
    organizationStore.setOrganizationInfo(null);
    setPageMode(PAGE_MODE.none);
  }, []);

  if (accountStore.isLoading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <div className={styles.organizationMainContainer}>
      <DetailContent
        pageMode={pageMode}
        setPageMode={setPageMode}
        open={Boolean(accountStore.selectedAccount?.id)}
        onClose={handleClose}
      />
    </div>
  );
};

export default observer(OrganizationPage);
