import { useCallback } from 'react';
import { debounce } from 'lodash';
import { observer } from 'mobx-react-lite';
import { Cancel } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { BulkFilterPanel } from './BulkFilterPanel';
import { SearchField, Tooltip } from '@/components';
import styles from './BulkFilter.module.css';
import { useBulkStore } from '../../stores/useBulkStore';

const PAGE_NAME = 'BulkTaskLog_Filter';

export const BulkFilter = observer(() => {
  const { filterStore, taskLogStore } = useBulkStore();

  const searchChangeHandler = (event) => {
    filterStore.setSearch(event.target.value.trim());
  };

  const debouncedChangeHandler = useCallback(debounce(searchChangeHandler, 500), []);

  return (
    <div id={`${PAGE_NAME}_container`} className={styles.filtersRoot}>
      <div className={styles.filterButtons} id={`${PAGE_NAME}_buttonGroupContainer`}>
      <div className={styles.actionsContainer}>
          <SearchField
            id="txt-log-search"
            placeholder="Search by id or title"
            value={filterStore.search}
            classes={{ root: styles.searchRoot }}
            onChange={debouncedChangeHandler}
          />
        </div>
        <BulkFilterPanel/>
        {taskLogStore.isSelectedTickets && (
          <>
            <Tooltip arrow title="Close the selected tickets" placement='top'>
              <IconButton
                id={`${PAGE_NAME}_closeButton`}
                size="small"
                onClick={() => taskLogStore.closeTickets()}
              >
                <Cancel fontSize="small" color="error" />
              </IconButton>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
});
