import { useRef, useState, useMemo } from 'react';
import cx from 'clsx';
import { Popover, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { Typography, AssignLabel, Tooltip } from '@/components';
import './TypeaheadDropdown.css';

const PAGE_NAME = 'TypeaheadDropdown';

export const TypeaheadDropdown = ({
  label,
  tooltipText,
  helperText,
  value,
  error,
  renderValue,
  options,
  activeFilter,
  classes,
  onChange,
  secondaryLabel,
  isDarkTheme,
  disabled,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState('');
  const selectedIndex = useRef(-1);
  const listRef = useRef(null);

  const selectedLabel = useMemo(() => {
    return options.find((item) => item.value === value)?.label;
  }, [value, options]);

  const filteredOptions = useMemo(() => {
    const result = [];
    options.forEach((option) => {
      if (activeFilter && !option.isActive) return;
      if (search) {
        const value = option.label;
        const startIndex = value?.toLowerCase().indexOf(search.toLowerCase().trim());
        if (startIndex !== -1) {
          result.push({ ...option, match: [startIndex, startIndex + search.length] });
        }
      } else {
        result.push({ ...option, match: [0, 0] });
      }
    });
    selectedIndex.current = result.findIndex((option) => option.value === value);
    return result;
  }, [options, search, activeFilter]);

  const handleClose = () => {
    setAnchorEl(null);
    setSearch('');
  };

  const handleDropDownKeyDownProcess = (event) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    }
  };

  const handleKeyDownProcess = (event) => {
    const optionCount = filteredOptions?.length;
    if (optionCount) {
      let isChangeSelectedIndex = false;
      if (event.keyCode === 40) {
        event.stopPropagation();
        selectedIndex.current = (selectedIndex.current + 1) % optionCount;
        isChangeSelectedIndex = true;
      } else if (event.keyCode === 38) {
        event.stopPropagation();
        selectedIndex.current = selectedIndex.current - 1;
        if (selectedIndex.current < 0) selectedIndex.current = optionCount - 1;
        isChangeSelectedIndex = true;
      } else if (event.keyCode === 13) {
        event.stopPropagation();
        handleClose();
      }
      if (isChangeSelectedIndex) {
        listRef.current.scrollTo(
          0,
          selectedIndex.current === optionCount - 1
            ? listRef.current.scrollHeight
            : ((listRef.current.scrollHeight - listRef.current.offsetHeight) *
                selectedIndex.current) /
                optionCount,
        );
        onChange(filteredOptions[selectedIndex.current].value);
      }
    }
  };

  return (
    <div className={cx('typeaheadV1', classes?.root)} id={`TypeaheadDropdown`}>
      {label && (
        <Tooltip placement="right" arrow title={tooltipText} className="typeaheadV1__tooltip">
          <div className="typeaheadV1__labelWrapper">
            <Typography
              variant="body1"
              className={cx('typeaheadV1__typeaheadLabel', error && 'typeaheadV1__errorColor')}
            >
              {label}
            </Typography>
            {tooltipText && <InfoIcon className="typeaheadV1__tooltipIcon" />}
          </div>
        </Tooltip>
      )}

      <div
        tabIndex={0}
        role="button"
        onKeyDown={handleDropDownKeyDownProcess}
        className={cx(
          'typeaheadV1__assignContainer',
          isDarkTheme && 'typeaheadV1__darkContainer',
          classes?.container,
          Boolean(anchorEl) && 'selected',
          error && 'typeaheadV1__errorBorder',
        )}
        onClick={(event) => !disabled && setAnchorEl(event.currentTarget)}
      >
        {renderValue ? (
          renderValue(value)
        ) : (
          <>
            {secondaryLabel && <span className="typeaheadV1__assignTo">{secondaryLabel}:</span>}
            <span className="typeaheadV1__assignTo">{selectedLabel}</span>
          </>
        )}
        <div className={cx('typeaheadV1__moreIcon', classes?.svgIcon, Boolean(anchorEl) && 'open')}>
          <ExpandMoreIcon />
        </div>
      </div>

      {helperText && (
        <Typography
          variant="body1"
          className={cx('typeaheadV1__typeaheadLabel', error && 'typeaheadV1__errorColor')}
        >
          {helperText}
        </Typography>
      )}

      <Popover
        anchorEl={anchorEl}
        id="TypeaheadDropdown_Popover"
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
        classes={{ paper: 'typeaheadV1__paper' }}
      >
        <div className="typeaheadV1__popoverContent">
          {filteredOptions.length === 0 && <Typography variant="body1">Assigned to:</Typography>}
          <TextField
            value={search}
            placeholder="Search members"
            classes={{ root: 'typeaheadV1__searchMembers' }}
            InputProps={{
              classes: {
                root: 'typeaheadV1_inputRoot',
                input: 'typeaheadV1_inputInput',
                notchedOutline: 'typeaheadV1_inputNotchedOutline',
                focused: 'typeaheadV1_inputFocused',
              },
            }}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={handleKeyDownProcess}
            autoFocus
            id="TypeaheadDropdown_SearchTextField"
          />
          <div
            className="typeaheadV1__listWrapper"
            ref={listRef}
            id="TypeaheadDropdown_ListContainer"
            style={{ paddingRight: filteredOptions.length > 5 ? 4 : 20 }}
          >
            {!filteredOptions.length && search && (
              <div
                className="typeaheadV1__noMatchingWrapper"
                id={`${PAGE_NAME}_noMatching_container`}
              >
                <SearchIcon className="typeaheadV1__noMatchingIcon" />
                <Typography variant="h6">No Matching Criteria</Typography>
                <Typography variant="body2" className="typeaheadV1__noMatchingLabel">
                  Please try another search term.
                </Typography>
              </div>
            )}
            {filteredOptions.map(({ value: v, label, type, match, email }) => (
              <AssignLabel
                key={v}
                id={v}
                isEditable
                checked={value === v}
                name={label}
                type={type}
                match={match}
                email={email}
                onChange={() => {
                  onChange(v);
                  handleClose();
                }}
              />
            ))}
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default TypeaheadDropdown;
