import { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { cloneDeep } from 'lodash';
import { useLocation } from 'react-router-dom';

import OrganizationContent from '../OrganizationContent';
import {
  Button,
  LoadingIndicator,
  ResizeGroupPanel,
  TextField,
  TooltipButton,
  Typography,
} from '@/components';
import { DEFAULT_ORGANIZATION, PAGE_MODE } from '@/utils/constants';
import { useSettingsStore, userStore } from '@/store';
import {
  getOrganizationInfo,
  isValidWebLink,
  showErrorNotification,
  showSuccessNotification,
} from '@/utils';
import { getValidPlatformLogin } from '@/utils/staticForm';
import { setError } from '@/utils/errors';

import styles from './DetailContent.module.css';

const PAGE_NAME = 'DetailContent';

const DetailContent = ({ open, pageMode, setPageMode }) => {
  const { organizationStore, accountStore, membersStore } = useSettingsStore();
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);
  const [orgInfo, setOrgInfo] = useState(null);
  const [currentOrgInfo, setCurrentOrgInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isViewMode = pageMode === PAGE_MODE.none || pageMode === PAGE_MODE.loading;

  const location = useLocation();
  const firstRender = useRef(true);

  const isOrganizationEditing = pageMode === PAGE_MODE.editing;

  const fetchOrganization = async () => {
    setIsLoading(true);
    try {
      organizationStore.setParentOrgId(accountStore.selectedAccount?.parentId);
      await organizationStore.fetchOrganizationInfo(accountStore.selectedAccount?.id);
      setPageMode(PAGE_MODE.none);
      setOrgInfo(getOrganizationInfo(organizationStore.organizationInfo));
      setCurrentOrgInfo(getOrganizationInfo(organizationStore.organizationInfo));
    } catch (err) {
      setError(err, true);
      showErrorNotification(err.preview ?? err.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (pageMode === PAGE_MODE.creating) {
      organizationStore.setOrganizationInfo(DEFAULT_ORGANIZATION);
      organizationStore.setParentOrgId(accountStore.selectedAccount?.id ?? userStore.organizationId);
      setOrgInfo(DEFAULT_ORGANIZATION);
    } else if (pageMode === PAGE_MODE.loading) {
      fetchOrganization();
    }
  }, [accountStore.selectedAccount, pageMode]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    fetchOrganization();
  }, [location.search]);

  const handleSaveOrganization = async () => {
    if (pageMode === PAGE_MODE.none) {
      setPageMode(PAGE_MODE.editing);
      return;
    }
    setIsSaveButtonClicked(true);
    const {
      name,
      profile: { branding = {}, eventSubscriptions = [], logins },
    } = orgInfo;

    if (
      name &&
      (!branding.helpUrl || isValidWebLink(branding.helpUrl)) &&
      logins.every((platformLogin) => getValidPlatformLogin(platformLogin)) &&
      !eventSubscriptions?.some(({ event, link }) => !event || !isValidWebLink(link))
    ) {
      setIsLoading(true);
      const result = await organizationStore.saveOrganizationInfo(
        accountStore.selectedAccount?.id,
        orgInfo,
        pageMode === PAGE_MODE.creating,
        membersStore.getAccountFromId(membersStore.allAccounts, organizationStore.parentOrgId),
      );
      if (result) {
        const result = await accountStore.fetchAccounts();
        if (result) membersStore.fetchAllAccounts(cloneDeep(result));
        let organizationInfo = null;
        if (pageMode === PAGE_MODE.creating) {
          organizationInfo = accountStore.getAccountFromName(accountStore.allAccounts, name);
          if (organizationInfo?.id) accountStore.onSelectAccount(organizationInfo?.id);
        } else {
          accountStore.onSelectAccount(orgInfo.id);
        }
        setIsSaveButtonClicked(false);
        setPageMode(PAGE_MODE.loading);
        showSuccessNotification('Organization successfully saved!');
      }
      setIsLoading(false);
    }
  };

  const handleChangeOrganization = (param) => {
    setOrgInfo({ ...orgInfo, ...param });
  };

  const renderTextField = (
    <TextField
      data-testid={`${PAGE_NAME}_orgInfo`}
      value={orgInfo?.name}
      error={isSaveButtonClicked && !orgInfo.name}
      onChange={(e) => handleChangeOrganization({ name: e.target.value })}
      style={{ width: '100%' }}
      inputClassName={styles.bordered}
    />
  );

  return (
    <>
      {open && isLoading && <LoadingIndicator />}
      <div className={styles.mainContainer}>
        <div id={`${PAGE_NAME}_container`} className={styles.title}>
          {open && Boolean(orgInfo) && !isLoading && (
            <div className={styles.taskContent}>
              <div className={styles.organizationHeader}>
                <div className={styles.titleWrapper}>
                  {isOrganizationEditing ? (
                    <>
                      <Typography variant="h6" className={styles.truncatedText}>
                        {pageMode === PAGE_MODE.creating ? orgInfo.name : `${orgInfo.id}: `}
                      </Typography>
                      <div className={styles.titleTextField}>{renderTextField}</div>
                    </>
                  ) : pageMode === PAGE_MODE.creating ? (
                    renderTextField
                  ) : (
                    <Typography variant="h6" className={styles.truncatedText}>
                      {`${orgInfo.id}: ${orgInfo.name}`}
                    </Typography>
                  )}
                </div>
                <div className={styles.buttonWrapper}>
                  {isViewMode && pageMode !== PAGE_MODE.creating && (
                    <TooltipButton
                      id={`${PAGE_NAME}_onBoard`}
                      data-testid={`${PAGE_NAME}_onBoard`}
                      tooltipText="Create a new organization"
                      variant="primary"
                      onClick={() => {
                        setPageMode(PAGE_MODE.creating);
                      }}
                    >
                      OnBoard
                    </TooltipButton>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <ResizeGroupPanel
          direction="horizontal"
          saveId={`${PAGE_NAME}_resize`}
          initialFirstSize={60}
          firstPanel={
            <div id={`${PAGE_NAME}_container`} className={styles.container}>
              {open && Boolean(orgInfo) && !isLoading && (
                <div className={styles.taskContent}>
                  <div className={styles.buttonsGroup}>
                    <Button
                      data-testid={`${PAGE_NAME}_saveButton`}
                      size="small"
                      variant="secondary"
                      onClick={handleSaveOrganization}
                      id={`${PAGE_NAME}_saveButton`}
                    >
                      {isViewMode ? 'Edit' : 'Save'}
                    </Button>
                    {!isViewMode && (
                      <Button
                        data-testid={`${PAGE_NAME}_cancelButton`}
                        size="small"
                        variant="tertiary"
                        onClick={() => {
                          setOrgInfo(currentOrgInfo);
                          setPageMode(PAGE_MODE.none);
                          setIsSaveButtonClicked(false);
                        }}
                        id={`${PAGE_NAME}_cancelButton`}
                      >
                        Cancel
                      </Button>
                    )}
                  </div>
                  <OrganizationContent
                    pageMode={pageMode}
                    orgInfo={orgInfo}
                    setOrgInfo={setOrgInfo}
                    showError={isSaveButtonClicked}
                    handleChangeOrganization={handleChangeOrganization}
                  />
                </div>
              )}
            </div>
          }
        >
          <div id={`${PAGE_NAME}_container_right`}>
            <Typography variant="h6" className={styles.truncatedText}>
              {'Organization Stats'}
            </Typography>
          </div>
        </ResizeGroupPanel>
      </div>
    </>
  );
};

export default observer(DetailContent);
