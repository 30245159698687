import { useRef, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cx from 'clsx';
import queryString from 'query-string';
import { debounce } from 'lodash';
import { observer } from 'mobx-react-lite';

import { Accordion, AccordionDetails, AccordionSummary, SearchField } from '@/components';
import SideMenuItem from './SideMenuItem';
import { BASE_ROUTES, UrlSearch } from '@/utils/types';
import { useMainStore } from '@/pages/main/stores/useMainStore';
import { userStore, useSettingsStore } from '@/store';

import styles from './Sidebar.module.css';

const PAGE_NAME = 'SideMenu';

const Sidebar = () => {
  const { accountStore, scheduleStore } = useSettingsStore();
  const { taskLogStore, detailStore } = useMainStore();

  const isLoading = taskLogStore.isLoading || accountStore.isLoading || scheduleStore.isLoading;
  const selectedOrgId = accountStore.selectedAccount?.id;
  const containerRef = useRef();
  const scrollableRef = useRef();
  const clickTimeout = useRef(null);

  const [sidebarWidth, setSidebarWidth] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const organizationsSidebar = location.pathname.includes('/organizations');

  const handleResize = useCallback(
    debounce(() => {
      if (containerRef.current?.parentNode) {
        const { offsetWidth } = containerRef?.current?.parentNode;

        if (offsetWidth !== sidebarWidth) {
          setSidebarWidth(offsetWidth >= 150 ? offsetWidth : 150);
        }
      }
    }, 50),
    [sidebarWidth],
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);

    if (containerRef.current?.parentNode) {
      resizeObserver.observe(containerRef.current?.parentNode);
    }

    handleResize();

    return () => {
      if (containerRef.current?.parentNode) {
        resizeObserver.unobserve(containerRef.current?.parentNode);
      }
    };
  }, [handleResize]);

  useEffect(() => {
    let hasScrolled = false;

    const performScroll = () => {
      if (
        !isLoading &&
        selectedOrgId &&
        accountStore.filteredAccounts.length > 0 &&
        scrollableRef.current &&
        !hasScrolled
      ) {
        const container = scrollableRef.current;

        const findPosition = (accounts) => {
          return accounts.some((account) => {
            if (account.id === selectedOrgId) {
              const element = document.getElementById(
                `sideNavigation_sideMenuItem_org_${selectedOrgId}`,
              );
              if (element) {
                const elementPosition = element.getBoundingClientRect();
                const containerPosition = container.getBoundingClientRect();

                const isVisible =
                  elementPosition.top >= containerPosition.top &&
                  elementPosition.bottom <= containerPosition.bottom;

                if (!isVisible) {
                  const scrollToPosition =
                    container.scrollTop +
                    (elementPosition.top - containerPosition.top);

                  container.scrollTo({
                    top: scrollToPosition,
                    behavior: 'smooth',
                  });
                  hasScrolled = true;
                }
              }
              return true;
            }
            if (account.children.length > 0) {
              return findPosition(account.children);
            }

            return false;
          });
        };
        findPosition(accountStore.filteredAccounts);
      }
    };
    const timeout = setTimeout(performScroll, 300);
    return () => clearTimeout(timeout);
  }, [isLoading, selectedOrgId, accountStore.filteredAccounts]);

  const handleOrganizationsSelect = (id) => {
    const pathName = window.location.pathname;
    const params = queryString.stringify({
      ...queryString.parse(window.location.search),
      organization: id,
    });
    navigate(`${pathName}?${params}`);
  };

  const handleSelect = useCallback(
    (e, id) => {
      e.stopPropagation();

      accountStore.setSearch('');

      if (clickTimeout.current) {
        clearTimeout(clickTimeout.current);
      }

      if (organizationsSidebar) {
        handleOrganizationsSelect(id);
        return;
      }

      if (id === selectedOrgId) {
        handleToggle(id);
      } else {
        const pathName = window.location.pathname;
        const params = queryString.stringify({
          ...queryString.parse(window.location.search),
          organization: id,
        });

        if (pathName.includes(BASE_ROUTES.detail)) {
          if (id !== accountStore.selectedAccount?.id) navigate(`${BASE_ROUTES.main}?${params}`);
        } else {
          const pathName = window.location.pathname;
          const params = new URLSearchParams(window.location.search);
          params.set(UrlSearch.organization, id);
          if (
            pathName === BASE_ROUTES.main &&
            id &&
            detailStore.organizationInfo &&
            !detailStore.organizationInfo.path.includes(id)
          ) {
            params.delete(UrlSearch.ticket);

            detailStore.setSelectedTicket();
          }

          if (pathName.includes(BASE_ROUTES.detail)) {
            if (id !== selectedOrgId) navigate(`${BASE_ROUTES.main}?${params}`);
          } else {
            navigate(`${pathName}?${params}`);
          }
        }
      }
    },
    [selectedOrgId],
  );

  const handleToggle = useCallback((id, e) => {
    e?.stopPropagation();
    accountStore.onToggleExpandNode(id);
  }, []);

  const debouncedSetSearch = useCallback(
    debounce((newSearch) => {
      accountStore.setSearch(newSearch);
    }, 300),
    [],
  );

  const handleSearchChange = (e) => {
    const newSearch = e.target.value;
    debouncedSetSearch(newSearch);
  };

  const renderSubOrganizationItem = useCallback(
    ({ id, name, children, isExpanded }, layer) => {
      const hasChildren = children && children.length > 0;

      if (accountStore.selectedAccount?.path?.includes(id)) {
        isExpanded = isExpanded !== undefined ? !!isExpanded : true;
      }

      return (
        <Accordion
          slotProps={{ transition: { unmountOnExit: true } }}
          key={id}
          classes={{
            accordionRoot: styles.accordionRoot,
            accordionExpanded: styles.accordionExpanded,
          }}
          id={`${PAGE_NAME}_organizations_accordion`}
          expanded={!!isExpanded}
        >
          <AccordionSummary
            id={`${PAGE_NAME}_organizations_accordion_content`}
            containerClassName={cx({
              [styles.accordionSummary]: true,
              [styles.accordionSelectedSummary]: selectedOrgId === id,
            })}
            contentClassName={styles.accordionSummaryContent}
          >
            <SideMenuItem
              sidebarWidth={sidebarWidth}
              layer={layer}
              isChild={hasChildren}
              isExpanded={!!isExpanded}
              isSelected={selectedOrgId === id}
              orgId={id}
              label={name}
              onSelect={(e) => handleSelect(e, id)}
              onToggleExpand={(e) => handleToggle(id, e)}
              search={accountStore.search}
            />
          </AccordionSummary>
          {hasChildren && !accountStore.search && (
            <AccordionDetails className={styles.accordionDetails}>
              {children.map((subOrganization) =>
                renderSubOrganizationItem(subOrganization, layer + 1),
              )}
            </AccordionDetails>
          )}
        </Accordion>
      );
    },
    [accountStore.search, selectedOrgId, sidebarWidth],
  );

  useEffect(() => {
    if (accountStore.allAccounts.length === 0) return;

    const params = queryString.parse(location.search);
    const orgIdFromUrl = params.organization
      ? Number(params.organization)
      : userStore.organizationId;

    if (orgIdFromUrl && orgIdFromUrl !== selectedOrgId) {
      accountStore.onSelectAccount(orgIdFromUrl);
    }
  }, [location.search, accountStore.allAccounts]);

  return (
    <div className={styles.sideContainer} ref={containerRef} style={{ minWidth: sidebarWidth }}>
      <div className={styles.sideContent}>
        <SearchField
          size="small"
          id="organizations-search"
          placeholder="Search"
          value={accountStore.search}
          classes={{ root: styles.searchRoot }}
          onChange={handleSearchChange}
        />
        <div id={`${PAGE_NAME}_sideMenuItem_group`} ref={scrollableRef} className={styles.body}>
          {accountStore.filteredAccounts.map((organization) =>
            renderSubOrganizationItem(organization, 1),
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(Sidebar);
