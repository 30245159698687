import { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import { ColumnType, UrlSearch } from '@/utils/types';
import {
  Chip,
  DataGridTable,
  EmptyFilterView,
  LoadingIndicator,
  Pagination,
  ResizeGroupPanel,
  TablePagination,
  Typography,
} from '@/components';
import { TicketLinkCell } from '@/components/Table';
import { DetailTicketContent } from './DetailTicketContent';
import { userStore } from '@/store';
import { useMainStore } from '../stores/useMainStore';
import { Filter } from './Filter';
import { EmptyLogView } from './EmptyLogView';
import { formatLocalDateTime, getMatch, SHORT_DATE_FORMAT } from '@/utils';
import CreateTicketModal from './CreateTicketModal';
import { AutomationLog } from '@/components/Table/AutomationLog';
import { PAGE_MODE } from '@/utils/constants';

import styles from './TaskLog.module.css';
import { TICKETS_COLUMNS } from './tableColumns';

const PAGE_NAME = 'TaskLog';

const TaskLog = () => {
  const {
    accountStore,
    taskLogStore,
    detailStore,
    tableStore,
    filterStore,
    createTaskStore,
    workflowStore,
  } = useMainStore();
  const isDataLoading = !userStore.states?.length || !accountStore.allAccounts?.length;

  const navigate = useNavigate();

  const handleRowClick = async (row) => {
    const pageParams = new URLSearchParams(window.location.search);
    const ticketId = row.id;
    if (ticketId) {
      pageParams.set(UrlSearch.ticket, ticketId);

      await detailStore.setSelectedTicket(ticketId);

      navigate({
        search: pageParams.toString(),
      });
    }
  };

  const onClose = useCallback(() => {
    const pageParams = new URLSearchParams(window.location.search);
    pageParams.delete(UrlSearch.ticket);
    pageParams.delete(UrlSearch.activeTicket);

    detailStore.setSelectedTicket();
    detailStore.setPageMode(PAGE_MODE.none);

    navigate({
      search: pageParams.toString(),
    });
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const ticketId = Number(searchParams.get(UrlSearch.ticket));

    if (!isDataLoading && ticketId) {
      detailStore.setSelectedTicket(ticketId);
    } else {
      detailStore.setSelectedTicket(null);
    }
  }, [isDataLoading]);

  const isLoading = taskLogStore.isLoading || accountStore.isLoading;

  const columns = useMemo(
    () => TICKETS_COLUMNS(filterStore.filteredSearch),
    [filterStore.filteredSearch],
  );

  const sortModel = useMemo(() => {
    const field = columns.find((column) => column.sortKey === tableStore.sortKey)?.field;
    if (!field) return [];
    return [
      {
        field,
        sort: tableStore.sortDirection,
      },
    ];
  }, [columns, tableStore.sortKey, tableStore.sortDirection]);

  if (
    !isLoading &&
    taskLogStore.parsedLogs?.length === 0 &&
    filterStore.allFilteredCount === 0 &&
    !filterStore.search
  ) {
    return <EmptyLogView />;
  }

  const handleSortModelChange = async (sortModel) => {
    if (sortModel.length > 0) {
      const { field, sort: sortDirection } = sortModel[0];
      const sortKey = columns.find((column) => column.field === field)?.sortKey;
      if (sortKey) tableStore.setSort(sortKey, sortDirection);
    } else {
      tableStore.setSortDirection(tableStore.sortDirection === 'asc' ? 'desc' : 'asc');
    }
  };

  return (
    <div className={styles.content} id={`${PAGE_NAME}_container`}>
      <div className={styles.container} id={`${PAGE_NAME}_contentContainer`}>
        {accountStore.isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <Filter />
            <ResizeGroupPanel
              direction="vertical"
              firstPanel={
                <>
                  {taskLogStore.isLoading && <LoadingIndicator />}
                  {!isLoading && (
                    <div className={styles.tableBlock} id={`${PAGE_NAME}_tableBlock`}>
                      {taskLogStore.parsedLogs.length === 0 &&
                      (filterStore.allFilteredCount > 0 || filterStore.search) ? (
                        <EmptyFilterView
                          noFilterResult={filterStore.allFilteredCount > 0}
                          onClick={() =>
                            filterStore.allFilteredCount > 0
                              ? filterStore.clearFilter()
                              : filterStore.setSearch('')
                          }
                        />
                      ) : (
                        <>
                          <DataGridTable
                            id="taskLogTable"
                            rows={taskLogStore.parsedLogs}
                            columns={columns}
                            rowSelectionModel={[detailStore.selectedId]}
                            onRowClick={handleRowClick}
                            onSortModelChange={handleSortModelChange}
                            sortModel={sortModel}
                            hideFooter
                            classNameBox={styles.tableContainer}
                          />
                          <div className={styles.pagination}>
                            <TablePagination
                              label="View Per Page"
                              value={tableStore.rowsPerPage}
                              id={`${PAGE_NAME}_paginationDropdown`}
                              options={[10, 20, 50, 100, 1000]}
                              onChangeRowsPerPage={(v) => tableStore.setRowsPerPage(v)}
                            />
                            <Pagination
                              count={taskLogStore.pageCount}
                              page={tableStore.pageNumber}
                              onChange={(e, v) => tableStore.setPageNumber(v)}
                              id={`${PAGE_NAME}_pagination`}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </>
              }
              showLastPanel={detailStore.isShowTicket}
            >
              <DetailTicketContent onClose={onClose} />
            </ResizeGroupPanel>
            <CreateTicketModal
              open={createTaskStore.isCreateMode}
              handleClose={() => {
                workflowStore.reset();
                createTaskStore.setCreateMode(null);
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default observer(TaskLog);
