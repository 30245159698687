import { useMemo } from 'react';

import { Typography, UploadAttachment } from '@/components';
import { WARNING_COLOR } from '@/utils/constants';
import { mapConfigFile } from '@/utils/staticForm';

import styles from '../DetailWorkflowContent.module.css';

const MultipleAttachments = ({ label, files, setFiles, warning, disabled }) => {
  const uploadedFiles = useMemo(() => (files ?? []).map(mapConfigFile), [files]);

  return (
    <div className={styles.contentItem}>
      <div className={styles.topic}>
        <Typography variant="subtitle2" sx={{ color: warning ? WARNING_COLOR : '' }}>
          {label}
        </Typography>
      </div>
      <UploadAttachment
        contentClassName={styles.uploader}
        onUpdateFile={(files) => {
          setFiles(files);
        }}
        files={uploadedFiles}
        warning={warning}
        multiple={false}
        disabled={disabled}
        hideDropZone={disabled}
      />
    </div>
  );
};

export default MultipleAttachments;
