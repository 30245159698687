import { FilterType, AVATAR_COLORS } from './constants';
import { formatLocalDateTime, SHORT_DATE_FORMAT } from './timeformat';
import { TRISTATE } from './types';

export const MemberStatus = {
  active: 'Active',
  invited: 'Invited',
  inactive: 'Inactive',
  contributor: 'Contributor',
  admin: 'Admin',
  assignable: 'Assignable',
};

export const USER_COLUMN_KEYS = {
  select: 'select',
  id: 'id',
  name: 'name',
  email: 'email',
  authId: 'authId',
  path: 'organizationPathByName',
  createdOn: 'createdOn',
  status: 'status',
  active: 'isActive',
  admin: 'isAdmin',
  assignable: 'isAssignable',
  contributor: 'isContributor',
};

export const MemberStatusList = [
  { key: USER_COLUMN_KEYS.active, label: MemberStatus.active },
  { key: USER_COLUMN_KEYS.admin, label: MemberStatus.admin },
  { key: USER_COLUMN_KEYS.assignable, label: MemberStatus.assignable },
  { key: USER_COLUMN_KEYS.contributor, label: MemberStatus.contributor },
];

export const MemberFilterType = {
  status: USER_COLUMN_KEYS.status,
  organization: 'organization',
};

export const InitUserFilters = {
  [FilterType.status]: {
    key: USER_COLUMN_KEYS.status,
    label: 'Status',
    values: [
      { value: MemberStatus.active },
      { value: MemberStatus.admin },
      { value: MemberStatus.assignable },
      { value: MemberStatus.contributor },
    ],
    selectedValues: [
      TRISTATE.unchecked,
      TRISTATE.unchecked,
      TRISTATE.unchecked,
      TRISTATE.unchecked,
    ],
  },
  [FilterType.viewBy]: {
    key: FilterType.viewBy,
    label: 'Show/Hide Columns',
    values: [
      { value: USER_COLUMN_KEYS.id, label: USER_COLUMN_KEYS.id },
      { value: USER_COLUMN_KEYS.name, label: USER_COLUMN_KEYS.name },
      { value: USER_COLUMN_KEYS.email, label: USER_COLUMN_KEYS.email },
      { value: USER_COLUMN_KEYS.authId, label: USER_COLUMN_KEYS.authId },
      { value: USER_COLUMN_KEYS.path, label: 'Organization Path' },
      { value: USER_COLUMN_KEYS.createdOn, label: 'Created Date' },
      { value: USER_COLUMN_KEYS.active, label: 'Active' },
      { value: USER_COLUMN_KEYS.admin, label: 'Admin' },
      { value: USER_COLUMN_KEYS.assignable, label: 'Assignable' },
      { value: USER_COLUMN_KEYS.contributor, label: 'Contributor' },
    ],
    selectedValues: [
      USER_COLUMN_KEYS.id,
      USER_COLUMN_KEYS.name,
      USER_COLUMN_KEYS.email,
      USER_COLUMN_KEYS.authId,
      USER_COLUMN_KEYS.path,
      USER_COLUMN_KEYS.status,
      USER_COLUMN_KEYS.active,
      USER_COLUMN_KEYS.admin,
      USER_COLUMN_KEYS.assignable,
      USER_COLUMN_KEYS.contributor,
    ],
  },
};

export const MemberSearchKeys = [
  USER_COLUMN_KEYS.id,
  USER_COLUMN_KEYS.name,
  USER_COLUMN_KEYS.email,
  USER_COLUMN_KEYS.authId,
  USER_COLUMN_KEYS.path,
  USER_COLUMN_KEYS.createdOn,
];

const getCheckboxValue = (params) => (params ? '✓' : '');

export const HEADER_COLUMNS = [
  {
    field: USER_COLUMN_KEYS.id,
    headerName: 'Id',
    width: 48,
  },
  {
    field: USER_COLUMN_KEYS.name,
    headerName: 'Name',
    flex: 1,
  },
  {
    field: USER_COLUMN_KEYS.email,
    headerName: 'Email',
    flex: 1.25,
  },
  {
    field: USER_COLUMN_KEYS.authId,
    headerName: 'AuthId',
    flex: 1.25,
  },
  {
    field: USER_COLUMN_KEYS.path,
    headerName: 'Path',
    flex: 1.5,
  },
  {
    field: USER_COLUMN_KEYS.createdOn,
    headerName: 'Created Date',
    width: 116,
    valueFormatter: (params) => formatLocalDateTime(params, SHORT_DATE_FORMAT),
  },
  {
    field: USER_COLUMN_KEYS.active,
    valueGetter: getCheckboxValue,
    headerName: 'Active',
    width: 64,
  },
  {
    field: USER_COLUMN_KEYS.admin,
    valueGetter: getCheckboxValue,
    headerName: 'Admin',
    width: 64,
  },
  {
    field: USER_COLUMN_KEYS.assignable,
    valueGetter: getCheckboxValue,
    headerName: 'Assignable',
    width: 88,
  },
  {
    field: USER_COLUMN_KEYS.contributor,
    valueGetter: getCheckboxValue,
    headerName: 'Contributor',
    width: 96,
  },
];

export const getFullName = (user) =>
  user.first ? `${user.first?.trim()} ${user.last?.trim()}` : null;

export const getAvatarColor = (name, email) => {
  const colorId = ((name || '') + (email || '')).split('').reduce((sum, char) => sum + char.charCodeAt(0), 0);

  return AVATAR_COLORS[colorId % AVATAR_COLORS.length];
};
