import { useState } from 'react';
import { Select, MenuItem, FormControl, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'; // DatePicker component
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'; // Date adapter
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { DEFAULT_CRONS } from '@/utils/constants';
import { getCronLabel } from '@/utils/cron';

export function CustomCronSelect(props) {
  const { value, id, onCronChange } = props;
  const [selectValue, setSelectValue] = useState(value);
  const [optionValue, setOptionValue] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);
  const handleSelectChange = (event) => {
    const option = DEFAULT_CRONS.find((item) => item.value === event.target.value);
    if (!option?.isCronValue) {
      setShowDatePicker(true);
      setOptionValue(option);
      setIsOpenDatePicker(true);
    } else {
      onCronChange(id, option.value);
      setSelectValue(option.value);
    }
  };
  const handleDateChange = (newValue) => {
    const day = newValue.getDate();
    const month = newValue.getMonth() + 1;
    const dayOfWeek = newValue.getDay();
    const newSelectedValue = optionValue?.cronFunc
      ? optionValue.cronFunc({ day, month, dayOfWeek })
      : selectValue;
    onCronChange(id, newSelectedValue);
    setIsOpenDatePicker(false);
    setShowDatePicker(false);
    setSelectValue(newSelectedValue);
  };
  return (
    <>
      {showDatePicker ? (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <FormControl
            fullWidth
            sx={{
              minHeight: '20px',
              '& .MuiFormControl-root': {
                height: '100%',
                padding: '0px',
                marginTop: '50px',
                display: 'flex',
                alignItems: 'center',
              },
              '& .MuiInputBase-root': {
                width: '100%',
                height: '100%',
                padding: 0,
                display: 'flex',
                alignItems: 'center',
              },
              '& .MuiOutlinedInput-root': {
                height: '100%',
                padding: 0,
                display: 'flex',
                alignItems: 'center',
              },
            }}
          >
            <DesktopDatePicker
              open={isOpenDatePicker}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </FormControl>
        </LocalizationProvider>
      ) : (
        <FormControl fullWidth>
          <Select
            labelId="select-label"
            value={selectValue}
            onChange={handleSelectChange}
            renderValue={getCronLabel}
            style={{ width: '100%', height: '100%' }}
          >
            {DEFAULT_CRONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
}
