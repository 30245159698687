import { useEffect, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { observer } from 'mobx-react-lite';
import { Route, Routes, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { omit } from 'lodash';

import { userStore, withStore } from './store';
import { Config } from './config';
import { showErrorNotification } from './utils/notificationToasts';
import {
  ErrorBoundary,
  ErrorBoundaryModal,
  LoadingIndicator,
  MainContainer,
  NotificationsContainer,
  SettingsMenu,
  Sidebar,
} from './components';
import { BASE_ROUTES } from './utils/types';
import {
  Members,
  OrganizationPage,
  TicketsPage,
  TopNavigation,
  BulkPage,
  TicketDetailPage,
  SettingsPage,
  EnvPage,
} from './pages';
import {
  getRedirectURLAfterLogin,
  removeRedirectURLAfterLogin,
  saveRedirectURLAfterLogin,
  getStoredAccessToken,
  saveToken,
} from './utils';

import './App.css';

function App() {
  const { isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently, logout } =
    useAuth0();
  const navigate = useNavigate();

  const localIsAuthenticated = !!getStoredAccessToken();

  const searchParam = window.location.search;
  const pagePath = window.location.pathname;
  const urlParams = new URLSearchParams(searchParam);
  const pageSettings = userStore.getPageSettings(pagePath);
  const leftSide = useMemo(() => {
    if (pagePath.includes(BASE_ROUTES.settings)) return <SettingsMenu />;
    return <Sidebar />;
  }, [pagePath]);

  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = Config.REACT_APP_AUTH0_DOMAIN;
      try {
        let accessToken = getStoredAccessToken();
        if (!accessToken) {
          accessToken = await getAccessTokenSilently({
            audience: `https://${domain}/api/v2/`,
            scope: 'read:current_user',
          });
          saveToken(accessToken);
        }
        userStore.login(accessToken);
      } catch (err) {
        showErrorNotification(err.preview ?? err.message);
      }
    };

    if (localIsAuthenticated || (!isLoading && isAuthenticated)) {
      getUserMetadata();
    }
  }, [isLoading, isAuthenticated, localIsAuthenticated]);

  useEffect(() => {
    if (
      (isAuthenticated || localIsAuthenticated) &&
      (userStore.isUnAuthorizedError || (userStore.currentUser.id && !userStore.isWFLAdminUser))
    ) {
      showErrorNotification('You are not authorized!');
      const timeoutId = setTimeout(() => logout({ returnTo: window.location.origin }), 500);
      return () => clearTimeout(timeoutId);
    }
  }, [
    isAuthenticated,
    localIsAuthenticated,
    userStore.currentUser.id,
    userStore.isWFLAdminUser,
    userStore.isUnAuthorizedError,
  ]);

  if (isLoading && !localIsAuthenticated) {
    return <LoadingIndicator fullScreen />;
  }

  if (isAuthenticated || localIsAuthenticated) {
    const redirectURL = getRedirectURLAfterLogin();
    if (redirectURL) {
      navigate(redirectURL);
      removeRedirectURLAfterLogin();
    }
    if (!userStore.lastUrlLoaded[pagePath] && pageSettings?.path) {
      userStore.setLastUrlLoaded(pagePath);
      if ((!searchParam && !pagePath.includes(BASE_ROUTES.detail)) || urlParams.has('code')) {
        navigate(`${pagePath}${pageSettings.path}`);
        return null;
      }
    }
    if (urlParams.has('code')) {
      navigate(
        `${pagePath}?${queryString.stringify(
          omit(queryString.parse(searchParam), ['code', 'state']),
        )}`,
      );
    }

    if (userStore.isUnAuthorizedError) return null;

    return (
      <ErrorBoundary>
        <NotificationsContainer />
        <div className="App">
          <TopNavigation />
          <MainContainer leftSide={leftSide}>
            <Routes>
              <Route path={BASE_ROUTES.main} element={<TicketsPage />} />
              <Route path={`${BASE_ROUTES.detail}/:ticketId`} element={<TicketDetailPage />} />
              <Route path={BASE_ROUTES.organizations} element={<OrganizationPage />} />
              <Route path={BASE_ROUTES.users} element={<Members />} />
              <Route path={BASE_ROUTES.bulk} element={<BulkPage />} />
              <Route path={BASE_ROUTES.settings} element={<SettingsPage />} />
              <Route path={BASE_ROUTES.env} element={<EnvPage />} />
            </Routes>
          </MainContainer>
        </div>
        {userStore.apiCriticalIssue && (
          <ErrorBoundaryModal
            url={userStore.apiCriticalIssue.url}
            payload={userStore.apiCriticalIssue.payload}
            preview={userStore.apiCriticalIssue.preview}
            statusText={userStore.apiCriticalIssue.statusText}
            isCritical={userStore.apiCriticalIssue.isCritical}
            isReload={userStore.apiCriticalIssue.isReload}
            errorAPIs={userStore.errorAPIs}
          />
        )}
      </ErrorBoundary>
    );
  } else {
    saveRedirectURLAfterLogin(`${pagePath}${searchParam}`);
    loginWithRedirect();
    return null;
  }
}

export default withStore(observer(App));
