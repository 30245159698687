import { useCallback, useEffect, useState } from 'react';
import { debounce, cloneDeep } from 'lodash';
import { observer } from 'mobx-react-lite';
import { SystemUpdateAlt } from '@mui/icons-material';

import { useMainStore } from '../../stores/useMainStore';
import { FilterPanel } from './FilterPanel';
import {
  Button,
  Chip,
  IconButton,
  MoreButtonPopover,
  SearchField,
  TooltipButton,
  Typography,
} from '@/components';
import { getFiltersQuery, getLogsEndpointURL, showErrorNotification } from '@/utils';
import { Config } from '@/config';
import { userStore } from '@/store';
import { accountStore } from '@/store/AccountStore';
import { MAX_QUERY_LENGTH } from '@/utils/constants';
import { HelpDeskStatus } from '@/utils/types';

import styles from './Filter.module.css';

const PAGE_NAME = 'TaskLog_Filter';

export const Filter = observer(() => {
  const { filterStore, taskLogStore, tableStore, createTaskStore, detailStore, workflowStore } =
    useMainStore();

  const [localFilters, setLocalFilters] = useState();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    handleApplyFilters();
  };

  const handleApplyFilters = () => {
    const query = getFiltersQuery(
      tableStore.pageNumber,
      tableStore.rowsPerPage,
      localFilters,
      filterStore.search,
      filterStore.hasIssuesFilter,
    );
    const sort = { key: tableStore.sortKey, dir: tableStore.sortDirection };
    const url = getLogsEndpointURL(
      `${Config.NEW_API}/Ticket/log`,
      userStore.organizationId,
      query,
      sort,
    );
    if (url.length < MAX_QUERY_LENGTH) {
      filterStore.updateFilterSelectedValues(localFilters);
    } else {
      showErrorNotification("You can't select too many filters");
    }
  };

  const handleResetFilters = () => {
    filterStore.clearFilter();
    setLocalFilters(cloneDeep(filterStore.filters));
    setAnchorEl(null);
  };

  const handleMyRequestsClick = () => {
    if (filterStore.myRequestsSelected) {
      filterStore.clearFilter();
    } else {
      filterStore.applyMyRequestFilter();
    }
  };

  const handleMyTasksClick = () => {
    if (filterStore.myTasksSelected) {
      filterStore.clearFilter();
    } else {
      filterStore.applyMyTaskFilter();
    }
  };

  const searchChangeHandler = (event) => {
    filterStore.setSearch(event.target.value.trim());
  };

  // eslint-disable-next-line
  const debouncedChangeHandler = useCallback(debounce(searchChangeHandler, 500), []);

  useEffect(() => {
    if (filterStore.filteredResult) setLocalFilters(cloneDeep(filterStore.filters));
    // eslint-disable-next-line
  }, [filterStore.filteredResult]);

  return (
    <div id={`${PAGE_NAME}_container`} className={styles.filtersRoot}>
      <div className={styles.filtersContainer}>
        <div className={styles.filterButtons} id={`${PAGE_NAME}_buttonGroupContainer`}>
          <SearchField
            id="txt-log-search"
            placeholder="Search by id or title"
            value={filterStore.search}
            classes={{ root: styles.searchRoot }}
            onChange={debouncedChangeHandler}
          />
        </div>
      </div>
      <div className={styles.actionsContainer}>
        <Button
          id="btn-my-requests"
          size="small"
          variant={filterStore.myRequestsSelected ? 'selected' : 'secondary'}
          onClick={handleMyRequestsClick}
        >
          <Typography className={styles.myListLabel}>My Requests</Typography>
        </Button>
        <Button
          id="btn-my-tasks"
          size="small"
          variant={filterStore.myTasksSelected ? 'selected' : 'secondary'}
          onClick={handleMyTasksClick}
        >
          <Typography className={styles.myListLabel}>My Tasks</Typography>
        </Button>
        <MoreButtonPopover
          triggerButton={
            <Button
              id="btn-filter"
              size="small"
              variant={anchorEl ? 'selected' : 'secondary'}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              Filter
              {filterStore.allFilteredCount > 0 && (
                <Chip
                  variant={HelpDeskStatus.badge}
                  label={filterStore.allFilteredCount}
                  className={styles.chip}
                />
              )}
            </Button>
          }
          onClose={handleClose}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        >
          <div className={styles.popoverHeader}>
            <Typography variant="subtitle1">Manage Filters</Typography>
            {!filterStore.getDefaultStatusSelected && (
              <Button
                id="btn-clear-filter"
                size="small"
                variant="tertiary"
                onClick={handleResetFilters}
              >
                Reset
              </Button>
            )}
          </div>
          <FilterPanel
            localFilters={localFilters}
            setLocalFilters={setLocalFilters}
            onApply={handleApplyFilters}
            onClose={() => {
              setAnchorEl(null);
            }}
          />
        </MoreButtonPopover>
        <IconButton
          id="btn-export-csv"
          size="small"
          tooltipArrow
          tooltipTitle="Export the tickets to CSV"
          onClick={() => taskLogStore.exportToCSV()}
        >
          <SystemUpdateAlt fontSize="small" color="info" />
        </IconButton>
        {!detailStore.isShowTicket && (
          <TooltipButton
            id={`${PAGE_NAME}_save_button`}
            tooltipText="Create a root ticket"
            variant="primary"
            disabled={detailStore.isEditing}
            onClick={() => {
              workflowStore.reset();
              createTaskStore.setCreateMode(accountStore.selectedAccount?.id);
            }}
          >
            Create
          </TooltipButton>
        )}
      </div>
    </div>
  );
});
