import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import moment from 'moment-timezone';

import { userStore } from '@/store';
import {
  getCurrentLocalDateTime,
  showErrorNotification,
  showSuccessNotification,
  updateTicketLogs,
} from '@/utils';
import { taskLogApi, ticketApi } from '@/api';
import { AutomationStatusColors, BASE_ROUTES, ColumnType } from '@/utils/types';
import { setError } from '@/utils/errors';

export class TaskLogStore {
  users = null;
  parsedLogs = [];
  pageCount = 1;
  isLoading = false;
  timeout = null;
  selectedTicket = null;
  assigns = [];
  assignOptions = [];

  constructor(accountStore, tableStore, filterStore) {
    makeObservable(this, {
      users: observable,
      parsedLogs: observable,
      pageCount: observable,

      assigns: observable,
      assignOptions: observable,
      ownerOptions: computed,

      selectedTicket: observable,
      selectedId: computed,
      isShowTicket: computed,
      canExecute: computed,
      setSelectedTicket: action,

      selectedTickets: computed,
      isSelectedAll: computed,
      isSelectedTickets: computed,
      isIndeterminate: computed,
      onToggleSelectTicket: action,
      onSelectAllTickets: action,

      closeTickets: action,

      isLoading: observable,
      setIsLoading: action,
    });

    this.accountStore = accountStore;
    this.tableStore = tableStore;
    this.filterStore = filterStore;

    this.disposeResetPagination = reaction(
      () => [this.accountStore.selectedAccount?.id],
      () => this.resetPagination(),
      { fireImmediately: true },
    );
  }

  get ownerOptions() {
    return this.assignOptions;
  }

  // NOTE: Get assign info
  getPicture(userId) {
    return this.users.find((user) => user.id === userId)?.picture;
  }

  // NOTE: Reset pagination settings
  resetPagination() {
    this.tableStore.setPageNumber(1);
  }

  async setSelectedTicket(ticket) {
    this.selectedTicket = ticket;
  }

  get selectedId() {
    return this.selectedTicket?.id;
  }

  get isShowTicket() {
    return Boolean(this.selectedTicket);
  }

  get canExecute() {
    const { name, automationTime } = this.selectedTicket?.automation ?? {};
    const localAutomationTime = moment(automationTime);
    const currentTime = moment();
    // Calculate difference in milliseconds
    const differenceInMilliseconds = currentTime.diff(localAutomationTime);
    const differenceInSeconds = moment.duration(differenceInMilliseconds).asSeconds();

    if (
      userStore.isWFLAdminUser &&
      name === 'Failed' &&
      automationTime &&
      differenceInSeconds > 30
    ) {
      const { rerun } =
        userStore.workflowConfigList.find(({ value }) => value === this.selectedTicket.workflow) ??
        {};
      return !!rerun;
    } else {
      return false;
    }
  }

  async executeTicket() {
    if (!this.selectedId) return;
    try {
      const buildId = await taskLogApi.executeTicket(this.selectedId);

      runInAction(() => {
        this.selectedTicket.automation = {
          ...this.selectedTicket.automation,
          name: 'Queued',
          color: AutomationStatusColors.Queued,
          automationTime: getCurrentLocalDateTime(),
        };
        showSuccessNotification(`Build Id ${buildId} is running!`);
      });
    } catch (err) {
      setError(err, false, 'Execute ticket failed');
      showErrorNotification(err.preview ?? err.message);
    }
  }

  async fetchBulkTaskLog(accountId, query, sort) {
    this.setIsLoading(true);
    try {
      const urlParams = window.location.search;
      const res = await taskLogApi.getBulkTaskLogs(accountId, query, sort);
      runInAction(() => {
        userStore.setUrlParams(BASE_ROUTES.bulk, urlParams);
        this.pageCount = Math.ceil(res.pageCount / this.tableStore.rowsPerPage);
        if (this.tableStore.pageNumber > this.pageCount) {
          this.tableStore.setPageNumber(1);
        }
        this.filterStore.filteredSearch = this.filterStore.search;
        this.parsedLogs = res.logs;
      });
    } catch (err) {
      setError(err, false);
    }
    this.setIsLoading(false);
  }

  get selectedTickets() {
    return this.parsedLogs.filter((ticket) => ticket[ColumnType.select]);
  }

  get isSelectedAll() {
    const selectedCount = this.selectedTickets.length;
    return selectedCount === this.parsedLogs.length;
  }

  get isSelectedTickets() {
    return this.selectedTickets.length > 0;
  }

  get isIndeterminate() {
    return !this.isSelectedAll && this.isSelectedTickets;
  }

  onToggleSelectTicket(id) {
    this.parsedLogs = this.parsedLogs.map((ticket) =>
      ticket.id === id ? { ...ticket, [ColumnType.select]: !ticket[ColumnType.select] } : ticket,
    );
  }

  onSelectAllTickets(value) {
    this.parsedLogs = this.parsedLogs.map((ticket) => ({
      ...ticket,
      [ColumnType.select]: value,
    }));
  }

  async closeTickets() {
    try {
      const res = await ticketApi.closeMultipleTickets(
        this.selectedTickets.map((ticket) => ticket.id),
      );
      if (res.result === 0) {
        //setError('No valid tickets to close', false, 'Close tickets failed');
        showErrorNotification('Close tickets failed');
        return;
      }

      if (!res.invalidTktIds) {
        this.selectedTickets.forEach((ticket) => {
          updateTicketLogs(this.parsedLogs, { ...ticket, state: 'Closed' });
        });
        showSuccessNotification('Tickets closed successfully');
        return;
      }

      if (res.invalidTktIds) {
        this.selectedTickets.forEach((ticket) => {
          if (!res.invalidTktIds.includes(ticket.id))
            updateTicketLogs(this.parsedLogs, { ...ticket, state: 'Closed' });
        });
        //setError(res.invalidTktIds.join(','), false, 'Some tickets failed to close');
        showErrorNotification('Some tickets failed to close');
        return;
      }
    } catch (err) {
      setError(err, false, 'Close tickets failed');
      showErrorNotification(err.preview ?? err.message);
    }
  }

  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  dispose() {
    this.disposeResetPagination();
  }
}
