import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import DoneIcon from '@mui/icons-material/Done';
import { CopyOutlineIcon } from '@/assets/icons';
import { Button, Typography, AsyncComponent } from '@/components';

import styles from './ErrorBoundaryPage.module.css';

const PAGE_NAME = 'ErrorBoundaryPage';
const MAX_TEXT_LIMIT = 2900;

export const ErrorBoundaryPage = observer(({ preview: errorInfo }) => {
  const url = window.location.href;
  const preview = `URL: ${url}\r\n\r\n${errorInfo}`;
  const previewCount = MAX_TEXT_LIMIT / 2;
  let filteredPreview;
  if (preview.length > previewCount) {
    filteredPreview = `${preview.substring(0, previewCount)}...\r\n\r\n...${preview.substring(
      preview.length - previewCount,
      preview.length,
    )}`;
  } else {
    filteredPreview = preview;
  }
  const errorMessage = `${filteredPreview || ''}\r\n`;
  const [isCopied, setIsCopied] = useState(false);

  const copyReport = async () => {
    await navigator.clipboard.writeText(errorMessage);
    setIsCopied(true);
  };

  const handleClickBack = () => {
    window.location.href = '/?page=1';
  };

  return (
    <div className={styles.notFoundPage}>
      <div id="NotFountPage_container" className={styles.notFoundContainer}>
        <Typography variant="h3">Sorry, Something Went Wrong</Typography>
        <Typography variant="h6" className={styles.title}>
          Please copy and paste the following code block so our developers can resolve this issue
          for you.
        </Typography>

        <div className={styles.copyCodeBlock}>
          <AsyncComponent
            component={Button}
            variant="secondary"
            size="medium"
            startIcon={isCopied ? <DoneIcon /> : <CopyOutlineIcon />}
            onClick={copyReport}
          >
            {isCopied ? 'Copied' : 'Copy code block'}
          </AsyncComponent>
        </div>

        <div className={styles.codeBlock}>
          <Typography variant="subtitle1" className={styles.errorMessage}>
            {errorMessage}
          </Typography>
        </div>
        <div className={styles.actions}>
          <Button
            size="large"
            id={`${PAGE_NAME}_backToHelpdesk`}
            variant="tertiary"
            onClick={handleClickBack}
          >
            Back to HelpDesk
          </Button>
        </div>
      </div>
    </div>
  );
});
