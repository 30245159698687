import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { DeleteIcon } from '@/assets/icons';
import { SaveAltIcon } from '@/assets/icons';
import { LoadingIndicator } from '@/components';
import { downloadFile } from '@/utils';
import styles from './CommentAttachmentArea.module.css';

const PAGE_NAME = 'CommentAttachmentArea';
const MAX_WORDS = 25;

export function CommentAttachmentArea({
  files,
  addingFiles,
  deletingFiles,
  maxWidth,
  onDeleteFile,
}) {
  const firstWords = (name) => {
    if (name.length >= MAX_WORDS) return name.substring(0, MAX_WORDS / 2);
    return name;
  };
  const secondWords = (name) => {
    if (name.length >= MAX_WORDS) return `...${name.substring(name.length + 3 - MAX_WORDS / 2)}`;
    return null;
  };

  return (
    <Grid container className={styles.attachedFiles}>
      {files
        .filter((file) => file)
        .map((file, idx) => (
          <Grid
            key={idx}
            item
            id={`${PAGE_NAME}_AttachedFiles_fileInfo`}
            className={styles.attachment}
            onClick={() => downloadFile(file)}
          >
            <span className={styles.fileName} style={{ maxWidth }}>
              {file.name}
            </span>
            {onDeleteFile ? (
              <DeleteIcon
                className={styles.cancelIcon}
                id={`${PAGE_NAME}_AttachedFiles_deleteIcon`}
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteFile(file);
                }}
              />
            ) : (
              <SaveAltIcon fontSize="small" className={styles.downloadIcon} />
            )}
            {deletingFiles?.find((item) => item.id === file.id) && (
              <div className={styles.deleteFile}>Deleting..</div>
            )}
          </Grid>
        ))}

      {addingFiles?.map((addingFile, index) => (
        <Grid
          item
          key={`${addingFile.name}_${index}`}
          className={styles.attachment}
          style={{ marginLeft: 20 }}
        >
          <div id={`${PAGE_NAME}_addedFile`} className={styles.uploadingFile}>
            <LoadingIndicator width={60} />
            <span className={styles.uploadingLabel}>{firstWords(addingFile.name)}</span>
            {secondWords(addingFile.name) && (
              <span className={styles.uploadingLabel}>{secondWords(addingFile.name)}</span>
            )}
          </div>
        </Grid>
      ))}
    </Grid>
  );
}

CommentAttachmentArea.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({})),
  addingFiles: PropTypes.arrayOf(PropTypes.shape({})),
  deletingFiles: PropTypes.arrayOf(PropTypes.shape({})),
  maxWidth: PropTypes.number,
  onDeleteFile: PropTypes.func,
};

CommentAttachmentArea.defaultProps = {
  files: [],
  addingFiles: [],
  deletingFiles: [],
  onDeleteFile: undefined,
  maxWidth: undefined,
};
