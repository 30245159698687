import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import clsx from 'clsx';
import { Close } from '@mui/icons-material';

import {
  AmazonStatus,
  Avatar,
  Button,
  CollapsibleSection,
  IconButton,
  LoadingIndicator,
  MultiLineInput,
  ResizeGroupPanel,
  TooltipButton,
  Typography,
  Tooltip,
  AsyncComponent,
} from '@/components';
import { InformationPanel } from '../InformationPanel';
import { useMainStore } from '../../stores/useMainStore';
import { DetailWorkflowContent } from '../DetailWorkflowContent';
import ScheduleTable from '../ScheduleTable';
import TriggersTable from '../TriggersTable';
import { CommentContent } from '../CommentContent';
import { AutomationLog } from '@/components/Table/AutomationLog';
import { BASE_ROUTES, MuiIcons, ObjectType, UrlSearch } from '@/utils/types';
import {
  getHyperText,
  getTicketLink,
  TICKET_DETAIL_RESIZE_SAVE_KEY,
  TICKET_TREE_RESIZE_SAVE_KEY,
} from '@/utils';
import { TicketTree } from '../TicketTree';
import { StatusSelect } from '../StatusSelect';

import styles from './DetailTicketContent.module.css';
import { userStore } from '@/store';
import { AvatarTooltip } from '@/components/Avatar/AvatarTooltip';
import { PAGE_MODE } from '@/utils/constants';
import { CustomDragLayer } from '../TicketTree/CustomDragLayer';
import { FeedbackPopover } from '../FeedbackPopover';

const PAGE_NAME = 'DetailTicketContent';

export const DetailTicketContent = observer(({ onClose }) => {
  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  const { detailStore, createTaskStore, workflowStore, routerStore } = useMainStore();
  const navigate = useNavigate();
  const { isEditing, currentTicket, parsedTicket: ticket, isShowTicket } = detailStore;
  const open = isShowTicket;
  const {
    ticketId,
    title,
    workflow,
    organization,
    organizationId,
    state,
    externalStatus,
    assignee,
    automation,
    objectType,
    description,
  } = currentTicket;
  const {
    configuration: hideConfig,
    schedules: hideSchedules,
    discussions: hideDiscussions,
  } = detailStore.isCollapse;
  const hyperText = useMemo(() => getHyperText(description || ''), [description]);

  const updateData = (fieldValue) => detailStore.updateTicketField(fieldValue);
  const isViewPage = Boolean(ticketId);

  const onCancel = useCallback(() => {
    detailStore.reloadCurrentTicket();
    workflowStore.reload(ticket?.config);
    workflowStore.reloadResult(ticket?.result);
    detailStore.setPageMode(PAGE_MODE.none);
  }, [ticket]);

  const handleSaveClicked = async () => {
    if (!detailStore.isEditing) {
      detailStore.reloadCurrentTicket();
      detailStore.setPageMode(PAGE_MODE.editing);
    } else {
      setSaveButtonClicked(true);
      const { title, estimatedCompletion, objectType, remainingWork } = currentTicket;
      if (
        (objectType === ObjectType.question && title) ||
        (title && (objectType === ObjectType.task || remainingWork) && estimatedCompletion)
      ) {
        const newData = {
          ...currentTicket,
          config: {
            ...currentTicket.config,
            ...(!workflowStore.defaultConfig?.hasSpecificData
              ? workflowStore.staticFormValue.value
              : workflowStore.specificData),
            SkipExecution: workflowStore.skipExecution,
          },
        };
        setSaveButtonClicked(false);
        detailStore.setPageMode(PAGE_MODE.none);
        const isConfigUpdate = true;
        await detailStore.updateTicket(
          newData,
          workflowStore.templateFiles,
          workflowStore.resultFiles,
          isConfigUpdate,
        );
        detailStore.reloadCurrentTicket();
      }
    }
  };

  const onActiveTicket = useCallback(
    (selectedId) => {
      if (detailStore.activeTicketId !== selectedId) {
        if (window.location.pathname.includes(BASE_ROUTES.detail)) {
          navigate(getTicketLink(selectedId));
        } else {
          routerStore.setSearchParam(UrlSearch.ticket, selectedId);
        }
        detailStore.setActiveTicketId(selectedId);
      }
    },
    [detailStore.activeTicketId],
  );

  const onMoveTicket = useCallback((dragId, toParentId) => {
    detailStore.fetchMoveTicket(dragId, toParentId);
  }, []);

  if (!open) return null;

  return (
    <>
      {detailStore.isLoading && !detailStore.familyTickets?.length && <LoadingIndicator />}
      {detailStore.familyTickets?.length > 0 && (
        <ResizeGroupPanel
          direction="horizontal"
          saveId={TICKET_TREE_RESIZE_SAVE_KEY}
          id={`${PAGE_NAME}_container`}
          firstPanel={
            <DndProvider backend={HTML5Backend}>
              <TicketTree
                data={detailStore.familyTickets}
                ticketId={detailStore.activeTicketId}
                onActiveTicket={onActiveTicket}
                isDragMode={detailStore.isEditing}
                onMoveTicket={onMoveTicket}
              />
              <CustomDragLayer dataList={detailStore.familyTickets} />
            </DndProvider>
          }
          showFirstPanel={isViewPage}
        >
          {detailStore.isLoading && <LoadingIndicator />}
          {!detailStore.isLoading && (
            <>
              <div className={styles.header}>
                <div className={styles.titleWrapper}>
                  <Typography variant="subtitle1">
                    <a href={getTicketLink(ticketId)} target="_blank" rel="noopener noreferrer">
                      {ticketId}
                    </a>
                  </Typography>
                  {isEditing ? (
                    <MultiLineInput
                      id={`${PAGE_NAME}_title_multiLineInput`}
                      placeholder="Provide a title for the task"
                      size="small"
                      error={saveButtonClicked && !title}
                      value={title}
                      maxLength={160}
                      onChange={(value) => updateData({ title: value })}
                      style={{ flex: 1 }}
                    />
                  ) : (
                    <Typography variant="subtitle1" className={styles.truncatedText}>
                      {title}
                    </Typography>
                  )}
                  <AutomationLog automation={automation} isAdminUser />
                </div>
                <div id={`${PAGE_NAME}_header`} className={styles.buttonsGroup}>
                  <AsyncComponent
                    component={TooltipButton}
                    id={`${PAGE_NAME}_remind_button`}
                    data-testid={`${PAGE_NAME}_remindButton`}
                    tooltipText="Remind assignee by sending an email."
                    variant="secondary"
                    disabled={isEditing}
                    onClick={async () => {
                      await detailStore.remindTicket();
                    }}
                  >
                    Remind
                  </AsyncComponent>
                  <TooltipButton
                    id={`${PAGE_NAME}_save_button`}
                    data-testid={`${PAGE_NAME}_createButton`}
                    tooltipText="Create a child ticket under the selected ticket."
                    variant="primary"
                    disabled={isEditing}
                    onClick={() => {
                      workflowStore.reset();
                      createTaskStore.setCreateMode(organizationId, ticketId);
                    }}
                  >
                    Create
                  </TooltipButton>
                  <Button
                    id={`${PAGE_NAME}_save_button`}
                    data-testid={`${PAGE_NAME}_saveButton`}
                    size="small"
                    variant={isEditing ? 'success' : 'secondary'}
                    onClick={handleSaveClicked}
                    classes={{ root: styles.saveButton }}
                  >
                    {detailStore.isEditing ? 'Save' : 'Edit'}
                  </Button>
                  {detailStore.isEditing && (
                    <Button
                      id={`${PAGE_NAME}_cancel_button`}
                      data-testid={`${PAGE_NAME}_cancelButton`}
                      size="small"
                      variant={isEditing ? 'error' : 'tertiary'}
                      onClick={onCancel}
                      classes={{ root: styles.cancelButton }}
                    >
                      Cancel
                    </Button>
                  )}
                  <IconButton
                    id="btn-export-csv"
                    data-testid={`${PAGE_NAME}_closeButton`}
                    size="small"
                    tooltipArrow
                    tooltipTitle="Close the panel"
                    onClick={onClose}
                  >
                    <Close fontSize="small" color="info" />
                  </IconButton>
                </div>
              </div>
              <div className={styles.tabHeader}>
                <Typography variant="subtitle2">{workflow}</Typography>
                <Typography variant="body2">
                  <Tooltip
                    title={`Platform(s): ${detailStore.orgPlatformList.join(', ') || 'None'}`}
                    placement="top"
                    arrow
                  >
                    <a
                      href={`${BASE_ROUTES.organizations}?${UrlSearch.organization}=${organizationId}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {organization}
                    </a>
                  </Tooltip>
                </Typography>
                <StatusSelect
                  id={`${PAGE_NAME}_statusSelect`}
                  value={state}
                  options={userStore.states}
                  onChange={(value) => detailStore.updateStatus(value)}
                  isDarkTheme
                />
                {objectType === ObjectType.case && externalStatus && (
                  <AmazonStatus id={`${PAGE_NAME}_externalStatus`} value={externalStatus} />
                )}
                <Avatar
                  data-testid={`${PAGE_NAME}_assigneeAvatar`}
                  name={assignee?.label}
                  variant={assignee?.type}
                  className={styles.assign}
                  tooltipArrow={false}
                  email={assignee?.email}
                  tooltipText={
                    <AvatarTooltip
                      name={assignee?.name}
                      email={assignee?.email}
                      variant={assignee?.type}
                    />
                  }
                />
                <IconButton
                  data-testid={`${PAGE_NAME}_discussions`}
                  size="small"
                  tooltipArrow
                  tooltipTitle="Discussions"
                  className={clsx(styles.collapseButton, {
                    [styles.active]: !hideDiscussions,
                  })}
                  onClick={() => detailStore.setIsCollapse({ discussions: false })}
                >
                  {MuiIcons.message}
                </IconButton>
                <IconButton
                  data-testid={`${PAGE_NAME}_configuration`}
                  size="small"
                  tooltipArrow
                  tooltipTitle="Configuration"
                  onClick={() => detailStore.setIsCollapse({ configuration: false })}
                  className={clsx(styles.collapseButton, {
                    [styles.active]: !hideConfig,
                  })}
                >
                  {MuiIcons.settings}
                </IconButton>
                <IconButton
                  data-testid={`${PAGE_NAME}_schedules`}
                  size="small"
                  tooltipArrow
                  tooltipTitle="Schedules"
                  onClick={() => detailStore.setIsCollapse({ schedules: false })}
                  className={clsx(styles.collapseButton, {
                    [styles.active]: !hideSchedules,
                  })}
                >
                  {MuiIcons.schedules}
                </IconButton>
                <FeedbackPopover ticketId={ticketId} />
              </div>
              {isViewPage && (
                <div className={styles.taskContent}>
                  <ResizeGroupPanel
                    saveId={TICKET_DETAIL_RESIZE_SAVE_KEY}
                    direction="horizontal"
                    firstPanel={
                      <>
                        {!detailStore.isCollapse?.discussions && (
                          <CollapsibleSection
                            title="Description"
                            defaultExpanded
                            className={styles.descriptionSection}
                          >
                            <div className={styles.row}>
                              {!isEditing && description && (
                                <Typography variant="body2" multiline sx={{ flex: 1 }}>
                                  <div dangerouslySetInnerHTML={{ __html: hyperText }} />
                                </Typography>
                              )}
                              {isEditing && (
                                <MultiLineInput
                                  id={`${PAGE_NAME}_description_multiLineInput`}
                                  placeholder="Provide a description of the task"
                                  value={description}
                                  size="small"
                                  onChange={(value) => updateData({ description: value })}
                                  style={{ flex: 1 }}
                                />
                              )}
                            </div>
                          </CollapsibleSection>
                        )}
                        <DetailWorkflowContent
                          config={currentTicket.config}
                          result={currentTicket.result}
                          workflowName={detailStore.workflowConfig}
                          isEditing={isEditing}
                        />
                        {!hideSchedules && (
                          <>
                            <ScheduleTable />
                            <TriggersTable
                              rows={detailStore.triggeredTickets}
                              orgId={detailStore.organizationId}
                            />
                          </>
                        )}
                        {!hideDiscussions && <CommentContent />}
                      </>
                    }
                    initialFirstSize={80}
                    classes={{
                      root: styles.detailsRoot,
                      firstPanel: styles.panel,
                      lastPanel: styles.panel,
                    }}
                  >
                    <InformationPanel
                      variant={objectType}
                      data={currentTicket}
                      updateData={updateData}
                      showError={saveButtonClicked}
                    />
                  </ResizeGroupPanel>
                </div>
              )}
            </>
          )}
        </ResizeGroupPanel>
      )}
    </>
  );
});
