import { FilterType } from './constants';
import { AssignType, RequestTypes } from './types';
import { formatUTCDateTime } from './timeformat';
import cronParser from 'cron-parser';

export function isCaseInsensitiveEqual(str1, str2) {
  return str1?.toLowerCase() === str2?.toLowerCase();
}

export function getCronError(cron) {
  try {
    cronParser.parseExpression(cron);
    return '';
  } catch (err) {
    return err.message;
  }
}

export const isStorybook = () => {
  return window.location.href.includes('viewMode=story');
};

export function getFirstLetter(str) {
  return str.charAt(0).toUpperCase();
}

export function getAvatarName(fullName) {
  if (!fullName) return '';
  const nameArray = fullName.split(' ');
  return (
    getFirstLetter(nameArray[0]) +
    (nameArray.length > 1 ? getFirstLetter(nameArray[nameArray.length - 1]) : '')
  );
}

export function getHyperText(text) {
  let hyperText = text || '';
  const markdownLinkPattern = /\[(.*?)\]\((.*?)\)/g;
  const markdownLinks = [...hyperText.matchAll(markdownLinkPattern)];
  markdownLinks?.forEach(
    ([match, title, url]) =>
      (hyperText = hyperText.replace(match, `<a href='${url}' target='_blank'>${title}</a>`)),
  );
  const urlRegex = /(?<!href=['"])(https?:\/\/[^\s]+)/g;
  const urls = hyperText.match(urlRegex);
  urls?.forEach(
    (url) => (hyperText = hyperText.replace(url, `<a href='${url}' target='_blank'>${url}</a>`)),
  );
  return hyperText;
}

export function getAvatarType(fullName) {
  const name = fullName.toLowerCase();
  let type = '';
  if (name.includes('amazon')) type = AssignType.amazon;
  if (name.includes('helpdesk') || name.includes('help desk')) type = AssignType.helpdesk;
  if (name.includes('workflow')) type = AssignType.workflow;
  return type;
}

export function isValidateEmail(email) {
  return (
    email &&
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
  );
}

export function isValidWebLink(link) {
  const regex = /^https?:\/\/([\w.-]+)(?:\.[a-z]{2,})+([/?].*)?$/;
  return regex.test(link);
}

export function getValidString(multiLineStr) {
  return multiLineStr
    ?.replace(/&quot;/g, '"')
    ?.replace(/&amp;/g, '&')
    ?.replace(/&lt;/g, '<')
    ?.replace(/&gt;/g, '>');
}

export function isJsonString(str) {
  try {
    var json = JSON.parse(str);
    return typeof json === 'object';
  } catch (e) {
    return false;
  }
}

export const convertToBool = (str) => str === true || str?.toLowerCase() === 'true';

export const getFiltersQuery = (pageNumber, rowsPerPage, filters, search) => {
  const query = {};
  query.page = pageNumber;
  query.count = rowsPerPage;
  query.owner = filters[FilterType.owner]?.selectedValues || [];
  query.creator = filters[FilterType.creator]?.selectedValues || [];
  query.assign = filters[FilterType.assign]?.selectedValues || [];
  query.automation = filters[FilterType.automation]?.selectedValues || [];
  query.status = filters[FilterType.status]?.selectedValues || [];
  query.account = filters[FilterType.account]?.selectedValues || [];
  query.services = filters[FilterType.service]?.selectedValues || [];
  query.types = filters[FilterType.type]?.selectedValues || [RequestTypes.all];
  query.updatedAfter = filters[FilterType.updatedAfter]?.selectedValues || [];
  query.createdAfter = filters[FilterType.createdAfter]?.selectedValues || [];
  query.lastUpdated = filters[FilterType.lastUpdated]?.selectedValues || [];
  query.error = filters[FilterType.error]?.selectedValues || '';
  query.search = search;
  return query;
};

export const getBulkFiltersQuery = (pageNumber, rowsPerPage, filters, search) => {
  const query = {};
  query.page = pageNumber;
  query.count = rowsPerPage;
  query.services = filters[FilterType.service]?.selectedValues || [];
  query.search = search;
  query.activeChildren = filters[FilterType.activeChildren]?.selectedValues[0] ? true : false;
  if (filters[FilterType.lastUpdated]?.selectedValues[0])
    query.lastUpdated = filters[FilterType.lastUpdated]?.selectedValues[0];
  return query;
};

export const getInternalTicketsEndpointURL = (origin, orgId, query, sort) => {
  const url = new URL(origin);
  url.searchParams.set('organizationId', orgId);
  url.searchParams.set('page', query.page);
  url.searchParams.set('rows', query.count);
  if (query.creator.length > 0) url.searchParams.set('createdByIds', query.creator);
  if (query.owner.length > 0) url.searchParams.set('ownedByIds', query.owner);
  if (query.assign.length > 0) url.searchParams.set('assignedToIds', query.assign);
  if (query.status.length > 0) url.searchParams.set('stateIds', query.status);
  if (query.automation.length > 0) url.searchParams.set('automation', query.automation);
  if (query.account.length > 0) url.searchParams.set('organizationIds', query.account);
  if (query.services.length > 0) url.searchParams.set('services', query.services);
  if (query.types.length > 0 && query.types[0] !== RequestTypes.all)
    url.searchParams.set('isRequest', query.types);
  if (query.search) url.searchParams.set('search', query.search);
  if (sort && sort.key && sort.dir)
    url.searchParams.set('sortColumns', `${sort.key} ${sort.dir.toUpperCase()}`);
  if (query.updatedAfter.length > 0)
    url.searchParams.set('updatedAfter', query.updatedAfter.map(formatUTCDateTime));
  if (query.createdAfter.length > 0)
    url.searchParams.set('createdAfter', query.createdAfter.map(formatUTCDateTime));
  if (query.error.length) url.searchParams.set('error', query.error);
  return url.toString();
};

export const getLogsEndpointURL = (origin, orgId, query, sort, csvDownload = false) => {
  const url = new URL(origin);
  url.searchParams.set('organizationId', orgId);
  if (!csvDownload) {
    url.searchParams.set('page', query.page);
    url.searchParams.set('count', query.count);
  } else {
    url.searchParams.set('download', true);
  }
  if (query.creator.length > 0) url.searchParams.set('createdByIds', query.creator);
  if (query.owner.length > 0) url.searchParams.set('ownedByIds', query.owner);
  if (query.assign.length > 0) url.searchParams.set('assignedToIds', query.assign);
  if (query.status.length > 0) url.searchParams.set('stateIds', query.status);
  if (query.automation.length > 0) url.searchParams.set('automationStates', query.automation);
  if (query.account.length > 0) url.searchParams.set('organizationIds', query.account);
  if (query.services.length > 0) url.searchParams.set('services', query.services);
  if (query.types.length > 0 && query.types[0] !== RequestTypes.all)
    url.searchParams.set('isRequest', query.types);
  if (query.search) url.searchParams.set('search', query.search);
  if (sort && sort.key && sort.dir)
    url.searchParams.set('sortColumns', `${sort.key} ${sort.dir.toUpperCase()}`);
  if (query.updatedAfter.length > 0)
    url.searchParams.set('lookBack', query.updatedAfter.map(formatUTCDateTime));
  if (query.createdAfter.length > 0)
    url.searchParams.set('createdAfter', query.createdAfter.map(formatUTCDateTime));
  if (query.lastUpdated.length > 0)
    url.searchParams.set('lastUpdated', query.lastUpdated.map(formatUTCDateTime));

  return url.toString();
};

export const getBulkLogsEndpointURL = (origin, orgId, query, sort) => {
  const url = new URL(origin);
  url.searchParams.set('organizationId', orgId);
  url.searchParams.set('page', query.page);
  url.searchParams.set('count', query.count);
  if (query.services.length > 0) url.searchParams.set('services', query.services);
  if (query.activeChildren) url.searchParams.set('activeChildren', true);
  if (sort && sort.key && sort.dir)
    url.searchParams.set('sortColumns', `${sort.key} ${sort.dir.toUpperCase()}`);
  if (query.search) url.searchParams.set('search', query.search);
  if (query.lastUpdated) url.searchParams.set('lastUpdated', query.lastUpdated);
  return url.toString();
};

export const getTriggeredTicketsEndpointURL = (origin, orgId, ticketId) => {
  const url = new URL(origin);
  url.searchParams.set('organizationId', orgId);
  url.searchParams.set('page', 1);
  url.searchParams.set('count', 1000);
  url.searchParams.set('sortColumns', `updatedOn DESC`);
  url.searchParams.set('search', `triggered by ${ticketId ? ticketId : ''}`);
  return url.toString();
};

export function getMatch(text, search) {
  if (!search) return null;
  const startIndex = text.toLowerCase().indexOf(search.toLowerCase());
  if (startIndex === -1) return null;
  return [startIndex, startIndex + search.length];
}

export function getWorkflowFromName(workflowList, selectedWorkflow) {
  if (selectedWorkflow) {
    for (let i = 0; i < workflowList.length; i++) {
      const workflow = workflowList[i];
      if (workflow.value === selectedWorkflow) return workflow;
      if (workflow.children?.length > 0) {
        const result = getWorkflowFromName(workflow.children, selectedWorkflow);
        if (result) return result;
      }
    }
  }
  return null;
}

export function getNodeItemFromId(treeList, selectedItemId) {
  if (selectedItemId) {
    for (let i = 0; i < treeList.length; i++) {
      const node = treeList[i];
      if (node.id === selectedItemId) return node;
      if (node.children.length > 0) {
        const result = getNodeItemFromId(node.children, selectedItemId);
        if (result) return result;
      }
    }
  }
  return null;
}
